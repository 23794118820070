<template>
  <div class="backdrop">
    <div class="b-modal">
        <div class="modal-header text-right">
            <h5 class="m-2 text-white">Upload Probity Checking Integration Invoice</h5>
            <p @click="closeModal" class="x">x</p>
        </div>
      <div class="modalBody">
      
           <div v-if="step == '3'" class="mt-5">
                <p style="color: white;">Upload successful.</p>
                <p>
                <button class="btn btn-secondary submit" @click="done">Done</button>
                </p>
            </div>
            

         <form v-on:submit.prevent="uploadImage($event)" v-if="step == '1'">
             <label for="idType"></label>
             <label class="form-check-label text-white" for="poa">
              Issue an Probity Checking Integration Invoice
             </label>
                <div class="payment-container text-left d-block">
                <form class="d-flex justify-content-between">
                 <p>Accepted file format: PDF, PNG, JPG, JPEG</p>
                  <p v-if="fileSizeExceeded" style="color: rgb(189, 64, 64);">File size exceeds the limit. Max: 4MB</p>
                  
                </form>
                </div>
                
            <div class="buttonWrapper" >
              
               
                <div class="dropbox text-white">
                  <input type="file" ref="fileInput" @change="handleFileSelect">
                  <p class="text-white">Selected file: {{ selectedFile }}</p>
                </div>
            </div>
            <div>
              <p >Add remarks:</p>
              <textarea class="form-control" v-model="description"></textarea>
            </div>
            <div v-if="!isLoading">
                <button type="submit" class="btn btn-secondary" id="saveBtn" >Submit</button>
                
               
            </div>
            <div v-else>
              <button type="submit" class="btn btn-secondary" id="saveBtn" disabled >Submitting...</button>
          </div>
            

            
        </form>
        
         
       
          <!--FAILED-->
          <div v-if="isFailed">
            <h2 class="red">Upload failed.</h2>
            <p v-if="fileSizeError" style="color: red">{{ fileSizeError }}</p>
            <p  class="red">
              <a href="javascript:void(0)" @click="closeModal()">Try again</a>
            </p>
          </div>
          <div class="text-center">
            <img class="iconImg" >
         </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    components: {

    },
    data: function() {
        return {
          uploadError:'',
          fileSizeError: null,
          referenceFileId: '1',
          description:'DIGITALTRUST-INVOICE',
            step:'1',
            idType:'Others',
            fileType:'DIGITALTRUST-INVOICE',
            selectedFile:'',
            selectedFileBack:'',
            idNumber:'111',
            uploadFieldName: 'imageFile',
            uploadFieldNameBack: 'imageFile',
            isSuccess: false,
            bothSuccess: false,
            isFailed: false,
            isLoading: false,
            maxSizeInBytes: 4 * 1024 * 1024, // Set your maximum file size limit in bytes (1MB in this example)
            fileSizeExceeded: false,
            response:'',
            errors: {
                profile: {
                    show:false,
                    msg: ""
                },
            },
            
        }
    },

    computed: {
     
    },

    mounted() {
     
    },

    watch: {
      
    },

    methods: {
      uploadNow(){
        this.step = '1'
      },
      handleFileSelect() {
          // const file = this.$refs.fileInput.files[0];
          // this.selectedFile = file ? file.name : '';

          this.fileSizeExceeded = false; // Reset the flag
            setTimeout(()=>{
              this.fileSizeExceeded = false 
            }, 2000)

            const selectedFile = event.target.files[0];
            if (selectedFile) {
              if (selectedFile.size > this.maxSizeInBytes) {
                this.fileSizeExceeded = true;
                // You can also reset the input or take other actions here.
                event.target.value = null; // Clear the selected file
              } else {
                // File size is within the limit, you can proceed with the upload.
                // You may want to emit an event or handle the file upload logic here.
              }
            }
          },
          handleFileSelectBack() {
          const file = this.$refs.fileInput.files[0];
          this.selectedFileBack = file ? file.name : '';
          },

        closeModal(){
            this.$emit("close")
            
        },

        done(){
          this.$emit("close");
          
        },
         uploadImage(event) {
            this.isLoading = true
            const params = {
              imageFile: this.selectedFile,
              description: this.description,
              entityId: this.$route.params.entityId,
              fileType: this.fileType,
              referenceFileId: this.referenceFileId
              }


         let data = new FormData();
                    data.append('imageFile', this.$refs.fileInput.files[0]);
                    data.append('entityId',this.$route.params.entityId); 
                    data.append('description',this.description); 
                    data.append('fileType',this.fileType); 
                    data.append('referenceFileId',this.referenceFileId); 
                    

            
            const options = {
                method: 'POST',
                headers: {
                      'content-type': 'image/png',
                      'vmiadmin-authz': localStorage.getItem("tokenId") 
                },
                data,
                url: '/ax/customer/upload/businessfile',
            };

           this.axios(options).then(
                response => {
                  this.isLoading = false
                    console.log(response.request.status)
                    this.response = response.request.status
                    
                    if(this.response = 200){
                     
                        setTimeout( () => {
                            this.isLoading = false
                            this.bothSuccess=true 
                            this.step = '3'
                        
                            
                        },1000)
                    } else {
                        this.isFailed = true
                        setTimeout(()=>{
                        this.isFailed = false
                      },2000)
                    }
                }
              ).catch((err) => {
                  if(this.response = 500){
                      this.isFailed = true
                      setTimeout(()=>{
                        this.isFailed = false
                      },2000)
                  }

          })
        },
       
      
    }
}
</script>

<style scoped lang=scss>
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);

}
.b-modal {
    width: 700px;
    height: auto;
    margin: 50px auto;
    background-color: #ffffff;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 99;
    overflow-x: hidden !important;
    color:white;
    max-height: calc(100vh - 110px);
    overflow-y: auto;
}

.modal-header {
  background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    margin-right:0px;
    font-size:25px;
    display:flex;
    justify-content:space-between;
}

.modal-title{
    margin: 0 auto;
    color:white;
}

.modalBody {
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    width:100%;
    padding:50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
}

.buttonWrapper {
    width:100%;
    margin-top: 10px;
    padding-top: 15px;
}

.x {
    cursor:pointer;
}
.red{
    color: red;
}




    @media screen and (max-width: 1100px) {
    
          
    }
   @media screen and (max-width: 800px) {
     
  }

    @media screen and (max-width: 600px) {
       
        
    }

    @media screen and (min-width: 280px) and (max-width: 420px) {

       .b-modal {
            width: 350px;
            height: auto;
            margin-top: 50px;
            background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
          background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
            width:100%;
            padding: 20px 10px;
            border-radius: 10px;
        }
        

  
      


    }

    @media screen and (min-width: 480px) and (max-width: 580px) {
        .b-modal {
                width: 405px;
        }

    
     
    }
    .wrapper{
      display: flex;
       padding: 2em;
      justify-content: center !important;
      margin: 0 auto !important;
  }
 
    .product-grid {
      display: flex;
     
    }

  .product-card {
      background-image: linear-gradient(
              180deg,
            #10184d 29%,
            #00103d 59%,
              #010c2a 88%
      );
      box-shadow:0 0 30px rgba(0,0,0,0.3);
      border: 1px solid transparent;
      border-radius: 12px;
      width: 7rem;
      height: auto;
      text-align: center;
      margin: 10px !important;
      color: white;
      cursor: none;
  
  }


  .product-image {
    padding: 10px 0px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      margin: 0 auto;
      background-color: none !important;
    width: 100%;
    max-width: 80px;
    height: auto;
    margin-bottom: 10px;
  }
</style>