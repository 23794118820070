<template>
	<div class="main">
    
	<div class="main-container">
          <button class="btn btn-secondary" :class="{ active: option == 'company'}" @click="changeState('company')" >
            Company Profile <b-badge class="text-dark" v-b-tooltip.hover title="For compliance Team. Click to view and conduct a due diligence on company profile">i</b-badge>
          </button> 
          <button class="btn btn-secondary" :class="{ active: option == 'individual'}" @click="changeState('individual')"  >
            Individual Associates <b-badge class="text-dark" v-b-tooltip.hover title="For compliance Team. Click to view and conduct a due diligence on authorized representative and its directors/ individual associates">i</b-badge>
          </button>
          <button class="btn btn-secondary" :class="{ active: option == 'complianceRating'}" @click="changeState('complianceRating')"  >
           Compliance Rating <b-badge class="text-dark" v-b-tooltip.hover title="For compliance Team. Click to view and rate the company's profile in terms of compliance">i</b-badge>
          </button>
          <button class="btn btn-secondary" :class="{ active: option == 'overall'}" @click="changeState('overall')"  >
           Compliance Report <b-badge class="text-dark" v-b-tooltip.hover title="For compliance Team. Click to view and upload the overall compliance rating">i</b-badge>
          </button>
          <button class="btn btn-secondary" :class="{ active: option == 'finalApproval'}" @click="changeState('finalApproval')"  >
           Final Approval <b-badge class="text-dark" v-b-tooltip.hover title="For compliance Team. Click to view and confirm by the final approver">i </b-badge>
          </button>
<!---->
                <div v-if="option == 'company'">
                  <CompanyDueDiligence/>
                </div>
                <div v-if="option == 'individual'">
                 <IndividualDueDiligence/>
                </div>
                <div v-if="option == 'complianceRating'">
                 <ComplianceRating/>
                </div>

              <hr>
            

              <div v-if="option == 'overall'" >
                <div v-if="isLoading" class="loading-spinner"></div>
                <button class="btn btn-secondary" @click="getResult()">Show Overall Scoring</button>
                 
                    <div v-if="showRating" class="d-block"> 
                      <div v-if="totalResult == 0"> Compliance Rating is not yet available</div>
                    <div class="d-flex justify-content-between">
                      <h4>OVERALL RATING </h4>
                      <h2 class="text-white">{{ totalResult }} / 100</h2>
                      <button class="btn btn-info" @click="exportToCsv()">save as csv</button>
                    </div>

                    <div v-if="totalResult > 0">

                      <div > 
                        <p>RISK LEVEL INDIVIDUAL</p>
                        <div class=""> 
                          <div class="market-content"> 
                            <table class="table" v-if="details.length > 0">
                              <thead>
                                <tr>
                                    <th>Rating</th>
                                    <th>ID</th>
                                    <th>Items to review</th>
                                    <th>Remarks</th>
                                    <th>Rated by</th>
                                    <th>Process Date</th>
                                </tr>
                              </thead>
                              <tbody v-if="rliIndividual.length == 0">
                                <td colspan="6">No Rating yet</td>
                              </tbody>
                              <tbody v-for="detail in rliIndividual" :key="detail.id">
                                <tr>
                                  <td>
                                    <button  class="btn btn-danger" v-if="detail.rate == '1'">HIGH</button>
                                    <button class="btn btn-warning" v-if="detail.rate == '2'">MEDIUM</button>
                                    <button class="btn btn-success" v-if="detail.rate == '3'">LOW</button>
                                  </td>
                                  <td>{{detail.reviewId}}</td>
                                  <td>{{detail.description}}</td>
                                  <td>{{detail.remarks}}</td>
                                  <td>{{detail.ratedBy}}</td>
                                  <td>{{formatDate(detail.updateDate)}}</td>
                                
                                </tr>
                              </tbody>
                             
                          </table>
                        </div>
                      </div>
                      
                    </div>
                    <div class=""> 
                          <p>RISK LEVEL CORPORATE</p>
                          <div class="market-content"> 
                            <table class="table" v-if="details.length > 0">
                              <thead>
                                <tr>
                                    <th>Rating</th>
                                    <th>ID</th>
                                    <th>Items to review</th>
                                    <th>Remarks</th>
                                    <th>Rated by</th>
                                    <th>Process Date</th>
                                </tr>
                              </thead>
                              <tbody v-if="rliCorporate.length == 0">
                                <td colspan="6">No Rating yet</td>
                              </tbody>
                             
                              <tbody v-for="detail in rliCorporate" :key="detail.id">
                                <tr>
                                  <td>
                                    <button  class="btn btn-danger" v-if="detail.rate == '1'">HIGH</button>
                                    <button class="btn btn-warning" v-if="detail.rate == '2'">MEDIUM</button>
                                    <button class="btn btn-success" v-if="detail.rate == '3'">LOW</button>
                                  </td>
                                  <td>{{detail.reviewId}}</td>
                                  <td>{{detail.description}}</td>
                                  <td>{{detail.remarks}}</td>
                                  <td>{{detail.ratedBy}}</td>
                                  <td>{{formatDate(detail.updateDate)}}</td>
                                
                                </tr>
                              </tbody>
                          </table>
                        </div>
                      </div>

                    </div>
                      
                  </div>
                <div> 
                 
                <p>
                  # of Uploaded report: {{ reportOnly.length }}
                </p>

                <div class="market-content"> 
                  <p v-if="reportOnly.length == 0">No available report found. Please check with the compliance team.</p>
                  <table >
                    <thead>
                      <th> Date Uploaded</th>
                      <th> File ID</th>
                      <th> Uploaded By</th>
                      <th> </th>
                      <th> Status</th>
                 
                    </thead>
                    <tbody v-for="item in reportOnly" :key="item.id">
                      <td>{{ item.createDate }}</td>
                      <td>{{ item.businessfileId }}</td>
                      <td>{{ item.uploadedBy }}</td>
                      <td><button @click.prevent="viewUpload(item)" class="btn btn-light text-dark" v-if="reportOnly.length != 0">
                    View Probity and Audit Summary/Conclusion Report
                  </button></td>
                  <td>
                    <button v-if="item.status == 'ACCEPTED'" class="btn btn-success">ACCEPTED</button>
                    <button v-if="item.status == 'SUBMITTED'" class="btn btn-dark">SUBMITTED</button>
                    <button v-if="item.status == 'REJECT'" class="btn btn-danger">REJECTED</button>
                  </td>
                  <td v-if="item.status == 'ACCEPTED'">Approved by: {{ item.processedBy }}</td>
                    </tbody> 
                  </table>
                  
                </div>
                <button class="btn-card-upload text-white" @click.prevent="uploadProbityReport()" :disabled="!init.FXCOMPLIANCE"> Upload Summary/ Conclusion Quarterly Report</button>
                </div>
              
                
              </div>
              <div v-if="option == 'finalApproval'"> 
                <div class="market-content"> 
                  <p v-if="reportOnly.length == 0">No available report found. Please check with the compliance team.</p>
                  <table >
                    <thead>
                      <th> Date Uploaded</th>
                      <th> File ID</th>
                      <th> Uploaded By</th>
                    
                      <th> Status</th>
                      <th> Approved By</th>
                    </thead>
                    <tbody v-for="item in reportOnly" :key="item.id">
                      <td>{{ item.createDate }}</td>
                      <td>{{ item.businessfileId }}</td>
                      <td>{{ item.uploadedBy }}</td>
                    
                  <td>
                    <button v-if="item.status == 'ACCEPTED'" class="btn btn-success">ACCEPTED</button>
                    <button v-if="item.status == 'SUBMITTED'" class="btn btn-dark">SUBMITTED</button>
                    <button v-if="item.status == 'REJECT'" class="btn btn-danger">REJECTED</button>
                  </td>
                  <td>{{ item.processedBy }}</td>
                    </tbody> 
                  </table>
                  
                </div>
                
                  <div v-if="show.profile.signupHost == 'gaming.ceza.org'"> 
                  <div v-if="init.tokens.length == 5">
                    <p >To be confirmed by the Approver</p>
                    <div >
                      <button class="btn btn-approve" @click.prevent="openUploadApprover('6')" :disabled="!init.FXSIGN">
                        Confirm 
                      </button>
                    </div>
                </div>
                </div>
                <div v-if="init.numberOfTokens >= 6">
                  <ul v-for="data in init.tokens" :key="data.id">
                    <li v-if="data.step == '6'">
                     <h5>Confirmed on <a :href="'https://explorer.blxq.org/tx/' + data.hash" target="_blank" class="link">blockchain</a> by: {{ data.issuedBy }} on {{ formatDate(data.updateDate) }}</h5> 
                     <!-- <button class="btn btn-secondary"> <a :href="'https://explorer.blxq.org/tx/' + data.hash" target="_blank">view on blockchain</a></button> -->
                    </li>
                  </ul>
                   
              </div>
                <div v-if="show.profile.signupHost == 'fintech.ceza.org'"> 
                  <div v-if="init.tokens.length == 4">
                    <p >To be confirmed by the Approver</p>
                    <div >
                      <button class="btn btn-approve" @click.prevent="openUploadApprover('5')" :disabled="!init.FXSIGN">
                        Confirm 
                      </button>
                    </div>
                </div>
                <div v-if="init.numberOfTokens >= 5">
                  <ul v-for="data in init.tokens" :key="data.id">
                    <li v-if="data.step == '5'">
                     <h5>Confirmed on <a :href="'https://explorer.blxq.org/tx/' + data.hash" target="_blank" class="link">blockchain</a> by: {{ data.issuedBy }} on {{ formatDate(data.updateDate) }}</h5> 
                     <!-- <button class="btn btn-secondary"> <a :href="'https://explorer.blxq.org/tx/' + data.hash" target="_blank">view on blockchain</a></button> -->
                    </li>
                  </ul>
                   
              </div>
                </div>
               <div>
                
               </div>
               
                </div>
              
            
          </div>
    <UploadProbityReport v-if="openUploadProbityReport" @close="uploadProbityReport()"></UploadProbityReport>
   
    <ViewUpload v-if="showUpload" @close="viewUpload(dataInfo)" :data="dataInfo" :format="item.fileType"></ViewUpload>
    <FinalApprove v-if="showUploadApprover" @close="openUploadApprover('5')" :step="step"></FinalApprove>
	</div>
  
 
          
</template>
        
<script>
  import moment from 'moment';
  import Chart from '../components/Chart.vue'
  import TheTopbar from '../components/TheTopbar.vue'
  import Header from '../components/Header.vue'
  import UploadProbityReport from '../components/modal/Documents/UploadProbityReport.vue'
  import ViewUpload from '../components/modal/Documents/ViewUpload.vue'
  import IndividualDueDiligence from '../view/IndividualDueDiligence.vue'
  import CompanyDueDiligence from '../view/CompanyDueDiligence.vue'
  import ComplianceRating from '../view/ComplianceRating.vue'
  import FinalApprove from '../components/modal/Documents/FinalApprove.vue'
  


  export default {
      name: 'ComplianceReview',
      components: {
        Chart,
        TheTopbar,
        Header,
        ViewUpload,
        IndividualDueDiligence,
        CompanyDueDiligence,
        FinalApprove,
        ComplianceRating,
        UploadProbityReport
      },
      
       data: function() {
      return { 
          option: 'company',
          tab:'namescreen',
          showWatchlistComment: false,
          showIdComment: false,
          showAddressComment: false,
          showNamescreenComment: false,
          showUpload: false,
          showUploadWatchlist: false,
          showUploadIdVerify: false,
          showUploadAddressVerify: false,
          showUploadNamescreen: false,
          showErrorMsg: false,
          watchlistComment:'',
          idComment: '',
          addressComment:'',
          namescreenComment:'',
          isSubmittedRate: false,
          msg:'',
          isApproved: false,
          showUpload: false,
          itemList: [],
          showAdd: false,
          showComment: false,
          showCountryForAddress: false,
          showCountry: false,
          country: 'PH',
          mobileCode: '63',
          list: {
                mobileCodes: []
          },
          acountry: 'AR',
          rate:'',
          remarks:'',
          reviewId:'',
          errorMsg:'',
          details:[],
          detailResult:[],
          detail:{},
          matchType:'close',
          whitelistPolicy:'apply',
          pep:'none',
          residence:'all',
          deceasedPerson: 'no',
          webSearch:'yes',
          fatfRisk:'yes',
          days: Array.from({ length: 31 }, (v, i) => (i + 1).toString().padStart(2, '0')),
          months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          years: Array.from({ length: 100 }, (v, i) => new Date().getFullYear() - i),
          month:'',
          day:'',
          year:'',
          isWatchlistMatch:false,
          watchList:[],
          listed:[],
          idTypes:[],
          item:{
            fileType:''
          },
          imgUrl:'',
          fileType: 'PROBITY REPORT',
          format:'',
          watchlistFile:'WATCHLIST',
          showUploadApprover: false,
          step:'',
          tableRating: false,
          chartRating: false,
          rateItem:[],
          showRating: false,
          openUploadProbityReport: false,
          riskLevel:'',
          isLoading: false
          
        }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
        listedOnly(){
          return this.watchList.filter(item => item.entityId === this.$route.params.entityId)
        },
       
        totalResult: function () {
          return (this.totalIdv + this.totalCpy) + this.totalBeh;
        },

        idvResult() {
          const filterItem = 'IDV'
          return this.details.filter((obj) => obj.description.slice(0,3).toUpperCase() === filterItem.toUpperCase())
        },
        cpyResult() {
          const filterItem = 'CPY'
          return this.details.filter((obj) => obj.description.slice(0,3).toUpperCase() === filterItem.toUpperCase())
        },
        behResult() {
          const filterItem = 'BEH'
          return this.details.filter((obj) => obj.description.slice(0,3).toUpperCase() === filterItem.toUpperCase())
        },
        rliResult() {
          const filterItem = 'RLI'
          return this.details.filter((obj) => obj.description.slice(0,3).toUpperCase() === filterItem.toUpperCase())
        },
        rliIndividual() {
          const filterItem = 'RLI-INDIVIDUAL'
          return this.details.filter((obj) => obj.description.slice(0,14).toUpperCase() === filterItem.toUpperCase())
        },
        rliCorporate() {
          const filterItem = 'RLI-CORPORATE'
          return this.details.filter((obj) => obj.description.slice(0,13).toUpperCase() === filterItem.toUpperCase())
        },
        totalIdv() {
          return this.idvResult.reduce((acc, item) => acc + item.rate, 0);
        },
        totalCpy() {
          return this.cpyResult.reduce((acc, item) => acc + item.rate, 0);
        },
        totalBeh() {
          return this.behResult.reduce((acc, item) => acc + item.rate, 0);
        },
        totalRli() {
          return this.rliResult.reduce((acc, item) => acc + item.rate, 0);
        },
        rliItems() {
          const filterItem = 'RLI'
          return this.itemList.filter((obj) => obj.description.slice(0,3).toUpperCase() === filterItem.toUpperCase())
        },
        reportOnly(){
          return this.idTypes.filter(item => item.fileType === this.fileType)
        }
     
      },
      methods:{
        uploadProbityReport(){
          this.openUploadProbityReport = !this.openUploadProbityReport
        },
        openUploadApprover(step){
          this.step = step
          this.showUploadApprover = !this.showUploadApprover
        },
        showAddItem(){
         this.showAdd = !this.showAdd
        },
        goToCompliance(){
          window.location.href="/#/compliance"
        },
        addComment(){
          this.showComment = !this.showComment
        },
        changeState(option) {
            this.option = option;
            localStorage.setItem("option", option);
          },
        opentab(tab) {
            this.tab = tab;
            localStorage.setItem("tab", tab);
        },
        getState() {
            let option = localStorage.getItem("option")
            if(option != "" || option != undefined) {
            this.option = option
            }
        },
        getTab() {
            let tab = localStorage.getItem("tab")
            if(tab != "" || tab != undefined) {
            this.tab = tab
            }
        },
        getResult(){
          this.isLoading = true
            this.showRating = true;

            const params = {
              entityId: this.$route.params.entityId
            }
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/review/items/entityid',
              }
              this.axios(options) 
              .then((response) => {
                this.isLoading = false
                console.log(response.data)
                this.details = response.data
                
              }).catch((err) => {
                  
              })
          },

          exportToCsv() {
                    this.getResult();
                      let filename = "customer-review" + "-" + this.$route.params.entityId + "-" + moment(String(new Date)).format('MM_DD_YYYY_HH:mm:ss') +  ".csv";
                        
                        let csv = []; 
                        let header1 = 'Review ID,Item name,Rate,Remarks,Rated by,Review Date';
                        csv.push(header1);

                    this.idvResult.forEach((el, i) => {
                        this.csvData = el
                        let row1 = this.csvData.reviewId + "," + this.csvData.description + "," + this.csvData.rate + '/' + '10' + "," + this.csvData.remarks + "," + this.csvData.ratedBy + "," + this.csvData.updateDate
                        csv.push(row1);

                    })
                    this.cpyResult.forEach((el, i) => {
                        this.csvData = el
                        let row1 = this.csvData.reviewId + "," + this.csvData.description + "," + this.csvData.rate + '/' + '10' + "," + this.csvData.remarks + "," + this.csvData.ratedBy + "," + this.csvData.updateDate
                        csv.push(row1);

                    })
                    this.behResult.forEach((el, i) => {
                        this.csvData = el
                        let row1 = this.csvData.reviewId + "," + this.csvData.description + "," + this.csvData.rate + '/' + '10' + "," + this.csvData.remarks + "," + this.csvData.ratedBy + "," + this.csvData.updateDate
                        csv.push(row1);

                    })
                   

                    let header2
                    csv.push(header2);
                    let header3 = "OVERALL RATING,''," + this.totalResult + '/' + '100';
                    csv.push(header3);

                    let header4
                    csv.push(header4);
                    
                    let header5 = "Review ID,Item name,Risk Level"
                    csv.push(header5);

                    let riskLevelScore = "";
                    this.rliResult.forEach((el, i) => {
                        this.csvData = el

                        if(this.csvData.rate == '3'){
                          riskLevelScore = 'LOW'
                        }
                        if(this.csvData.rate == '2'){
                          riskLevelScore = 'MEDIUM'
                        }
                        if(this.csvData.rate == '1'){
                          riskLevelScore = 'HIGH'
                        }

                        let row5 = this.csvData.reviewId + "," + this.csvData.description + "," + riskLevelScore + "," + this.csvData.remarks + "," + this.csvData.ratedBy + "," + this.csvData.updateDate
                        csv.push(row5);

                    })

                    this.downloadCsv(csv.join("\n"), filename);
                
                

            },
            downloadCsv(csv,filename) {
                let csvFile;
                let downloadLink;
                csvFile = new Blob([csv], {type: "text/csv"});
                downloadLink = document.createElement("a");
                downloadLink.download = filename;
                downloadLink.href = window.URL.createObjectURL(csvFile);
                // downloadLink.style.display = "none";
                document.body.appendChild(downloadLink);

                downloadLink.click();
            },
        openUploadWatchlist(result){
          this.watchlistComment = result
          this.showUploadWatchlist = !this.showUploadWatchlist
        },
        openUploadIdVerify(){
          this.showUploadIdVerify = !this.showUploadIdVerify
        },
        openUploadAddressVerify(){
          this.showUploadAddressVerify = !this.showUploadAddressVerify
        },
        openUploadNamescreen(){
          this.showUploadNamescreen = !this.showUploadNamescreen
        },
        updateDate() {
          if (this.day && this.month && this.year) {
            // Do any additional validation or formatting if needed
            const formattedDate = `${this.day.toString().padStart(2, '0')} ${this.month.toString().padStart(2, '0')} ${this.year.toString()}`;
            console.log('Selected Date:', formattedDate);
          }
        },
        getListOfItems(){
          this.isLoading = true
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              url: '/ax/review/items'
              }
              this.axios(options)
              .then((response) => {
                this.isLoading = false
                this.itemList = response.data

              }).catch((err) => {
                  
              })
        },
       
        formatDate: function(date) {
          return moment(new Date(date)).format('MM/DD/YYYY hh:mm:ss A');
        },
        submitReview(item){
            this.isSubmittedRate = false;
            this.reviewId = item.reviewId
            this.rate = item.rate
            this.remarks = item.remarks

            let hasError = false;
            if(this.rate == '' || this.rate == undefined || this.rate == null){
              this.showErrorMsg = true
              this.errorMsg = 'Rating is required.'
              hasError = true
              setTimeout(()=>{
                this.showErrorMsg = false,
                this.errorMsg = ''
              }, 2000)
            }
            if(this.remarks == '' || this.remarks == undefined || this.remarks == null){
              this.showErrorMsg = true
              this.errorMsg = 'Remarks is required.'
              hasError = true
              setTimeout(()=>{
                this.showErrorMsg = false,
                this.errorMsg = ''
              }, 2000)
            }
           

            const params = {
              reviewId: this.reviewId,
              rate: this.rate,
              remarks: this.remarks,
              entityId: this.$route.params.entityId
            }

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/review/rate'
              }
              this.axios(options)
              .then((response) => {
               window.location.reload();
               
              
              }).catch((err) => {
                  
              })
          },
          getResult(){
            this.isLoading = true
            this.showRating = true

            const params = {
              entityId: this.$route.params.entityId
            }
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/review/items/entityid',
              }
              this.axios(options) 
              .then((response) => {
                this.isLoading = false
                console.log(response.data)
                this.details = response.data
                
              }).catch((err) => {
                  
              })
          },
          viewReview(item){
            this.isLoading = true
            this.showRating = true
            this.reviewId = item.reviewId
            
            const params = {
              reviewId: this.reviewId
            }
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/review/rate/list',
              }
              this.axios(options) 
              .then((response) => {
                this.isLoading = false
                console.log(response.data)
                this.details = response.data

                
              }).catch((err) => {
                  
              })
          },
         
          getWatchlist(){
                const options = {
                    method: 'GET',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: ' /ax/watchlist',
                    };

                  this.axios(options)
                  .then((response) => { 
                  console.log(response.data)
                  this.watchList = response.data

                  this.watchList.forEach((el, i) => {
                    console.log(el.entityId)
                    if(el.entityId == this.$route.params.entityId){
                      this.isWatchlistMatch = true
                      this.listed = el
                    }
                  })
                
                })
          },
          getFiles(){
            this.isLoading = true
          const params = {
            entityId: this.$route.params.entityId
            }
           
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/customer/businessfile/' 
              }
              this.axios(options)
              .then((response) => {
                this.isLoading = false
                this.idTypes = response.data
                let item = [];
                this.idTypes.forEach((el, i) => {
                  item.push(el);
                  this.imgUrl = el.imageFile
                  });

                  this.item.fileType = this.imgUrl.split('.').pop();
                  console.log(this.item.fileType)

               
              }).catch((err) => {
                  
              })
          },
            viewUpload(docData){
            this.dataInfo = docData
            this.showUpload = !this.showUpload
          },
            download(item){
            this.imageUrl = item.imageFile
            this.fileType = this.imageUrl.split('.').pop();

              const params = {
                entityId: this.$route.params.entityId
            }
              
            const options = {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId") 
                    }, 
                    params,
                    url: '/ax/customer/businessfile/image/' + item.businessfileId,
                    }
                    this.axios(options)
                    .then((response) => {
                      
                      
                    if(this.fileType === 'pdf'){
                      const link = document.createElement('a');
                      link.href = "data:application/pdf;base64," + response.data
                      link.download = 'file'; 
                      link.click();
                    }

                    if(this.fileType !== 'pdf'){
                      const link = document.createElement('a');
                      link.href = "data:image/png;base64," + response.data
                      link.download = 'file'; 
                      link.click();
                    }
                  
                    
                    }).catch((err) => {
                        
                    })
          },
          getProfile() {
            const params = {
              eid: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              data,
              url: '/ax/getCustomerProfile/',
              }
              this.axios(options)
              .then((response) => {
                
                this.show.profile = response.data;
                this.show.corpDetails = response.data.corpDetails
                
              
              }).catch((err) => {
                  
              })
          },
          openWatchlist(){
            this.showWatchlistComment = !this.showWatchlistComment;
          },
          openIdVerify(){
            this.showIdComment = !this.showIdComment;
          },
          openAddressVerify(){
            this.showAddressComment = !this.showAddressComment;
          },
          openNamescreen(){
            this.showNamescreenComment = !this.showNamescreenComment;
          },
          addToWatchlist(){
            const params = {
              remarks: this.watchlistComment,
              entityId: this.$route.params.entityId

            }
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/watchlist/add',
              }
              this.axios(options)
              .then((response) => {
                window.location.reload();
              }).catch((err) => {
              })
          
          },
          moveToWatchlist(){
             const params = {
		          eid: this.$route.params.entityId,
                  siteHost: "watchlist.ceza.org",
		        }

		        const data = Object.keys(params)
		          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
		          .join('&');
		   
                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/updateCustomerHost',
                    data
                    };

                this.axios(options)
                .then((response) => { 
                   
                  window.location.reload();
                   
                })
            },
        
          
        toggleCountryListForAddress(){
            this.showCountryForAddress = !this.showCountryForAddress;
          },
          selectCountryForAddress: function(c) {
            this.showCountryForAddress = false;
            this.acountry = c.countryCode
           
          },
         
              
          getMobileCodes: function() {
          this.axios.get('/assets/js/mobilecodes.json')
              .then((response) => {
              this.list.mobileCodes = response.data;
              }).catch((err) => {
              console.log(err);

              })
          },
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
        this.axios(options)
              .then((response) => {
              
              this.getProfile();
              }).catch((err) => {
                 

                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
      mounted(){
          this.sessionCheck();
          this.getListOfItems();
          this.getMobileCodes();
          this.getWatchlist();
          this.getFiles();
          this.getTab();
          this.getState();
          // this.getRatingResult();
      }
  }
</script>
        
<style scoped lang="scss">
    * {
    box-sizing: border-box;
  }
  
  /* Create two equal columns that floats next to each other */
  .column {
    float: left;
    width: 50%;
    padding: 10px;
  
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }
  .checkbox label{
    font-size: 12px !important;
  }
.header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }

  
  .market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
   
  .content-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    justify-content: center !important;
    /* max-width: 1000px; */
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #ffffff;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
    
  }
 
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
  .active {
    background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;;
  }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}
.btn .btn-checkbox{
  padding: 0px !important;
  margin: 0px !important;
}
.btn-checkbox .checkbox{
  width: 20px !important;
}
label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}

   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 200px;
  border-radius: 10px;

  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}




   

.icon img{
  width: 2rem;
}
.card{
  background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
  );
    box-shadow:0 0 30px rgba(0,0,0,0.3);
    border: 1px solid transparent;
    border-radius: 12px;
    width: 100%;
    height: auto;
    text-align: center;
    color: white;
}


.verified{
  background-color: green;
  color: white;
}
.submitted{
  background-color: green !important;
  color: white;
}
.notSubmitted{
  background-color: red;
  
}
  
 
  
    .progress-bars {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    /* margin-bottom: 20px; */
  }
  .btn-approve{
       width: 100%;
       background-color: green !important;
        color: white;
    }
  .progress-bar {
    width: 100px;
    height: 50px;
    background-color: lightgray;
    background-color: #ffffff !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
  }
  
  .active {
    background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;;
    color: white !important;
  }

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }

  .progress-bar {
    width: 80px;
    height: 50px;
    background-color: lightgray;
    background-color: #ffffff !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
    
  }
  
}
@media (max-width: 1280px) {

  
}
  
  label {
    margin-right: 5px;
  }
  

.btn-card-upload{
  width: 100%;
  height: auto;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: gray !important;
  color: black;
  display: flex;
  justify-content: space-between;
 
}

.indicator img{
  width: 1rem;
}
.indicator-progress img{
  width: 1rem;
}

</style>
