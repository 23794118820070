<template>
	<div class="main">
    
	<div class="main-container" v-if="option == 'company'">
        
              <div >
                <button  class="btn btn-secondary"  @click="opentab('watchlist')"   v-b-tooltip.hover title="For compliance Team. Click to make a watchlist decision">
                  Watchlist
                </button>
                <!-- <button  class="btn btn-secondary"  @click="opentab('idVerify')" >
                  ID Verification
                </button> -->
                <button  class="btn btn-secondary"  @click="opentab('addressVerify')"   v-b-tooltip.hover title="For compliance Team. Click to validate the company's business address">
                  Address Verification
                </button>
                <button  class="btn btn-secondary"  @click="opentab('namescreen')"  >
                  Namescreen result <b-badge class="text-dark" v-b-tooltip.hover title="For compliance Team. Click to screen company's name for adverse media and manage AML/CTF risk">i</b-badge>
                </button>
                
                <div v-if="tab == 'watchlist'">
                    <div class="market-content"> 
                      <p>Watchlist History</p>
                    <div class="market-content" >
                      <table>
                        <thead>
                          <tr>
                            <th>
                              Processed by:
                            </th>
                            <th>
                              Date:
                            </th>
                            <th>
                              Decision(risk)
                            </th>
                          
                          </tr>
                        </thead>
                        <tbody v-for="item in listedOnly" :key="item.id">
                          <tr v-if="item.directorId == 0">
                            <td>
                              {{ item.addedBy }}
                            </td>
                            <td>
                              {{ formatDate(item.dateCreated )}}
                            </td>
                            <td>
                              {{ item.remarks }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </div>
                    <button @click="openWatchlist()" class="btn btn-secondary">match decision</button>
                
                </div> 

                <!-- <div v-if="tab == 'idVerify'"> 
                  <div class="market-content"> 
                    <p>BRN Verification : </p>
                <table class="table">
                  <thead>
                    <tr >
                        <th v-if="show.profile.idVerified == true">VERIFIED</th>
                        <th v-if="show.profile.idVerified == false">UNVERIFIED</th>
                        
                    </tr>
                  </thead>
                
                </table>
                <div class="market-content" >
                      <table>
                        <thead>
                          <tr>
                            <th>
                              Processed by:
                            </th>
                            <th>
                              Date:
                            </th>
                            <th>
                              Decision(risk)
                            </th>
                          
                          </tr>
                        </thead>
                       
                      </table>
                    </div>
              </div>
              <button @click="openIdVerify()"  class="btn btn-secondary">match decision</button>
                </div> -->

                <div v-if="tab == 'addressVerify'"> 
                  <div class="market-content"> 
                    <p>Address Verification: 
                    <span v-if="show.corpDetails.corpOfficeAddress != null">{{ show.corpDetails.corpOfficeAddress.fullAddress }}</span>
                  <span v-else><button class="btn btn-main" @click="goToCompany">view</button></span></p>
                <table class="table">
                  <thead>
                    <tr >
                        <th v-if="show.profile.addressVerified == true">VERIFIED</th>
                        <th v-else>UNVERIFIED</th>
                        
                    </tr>
                  </thead>
                
                </table>
                <div class="market-content" >
                      <table>
                        <thead>
                          <tr>
                            <th>
                              Processed by:
                            </th>
                            <th>
                              Date:
                            </th>
                            <th>
                              Decision(risk)
                            </th>
                          
                          </tr>
                        </thead>
                        <!-- <tbody v-for="item in listedOnly" :key="item.id">
                          <tr v-if="item.directorId == 0">
                            <td>
                              {{ item.addedBy }}
                            </td>
                            <td>
                              {{ formatDate(item.dateCreated )}}
                            </td>
                            <td>
                              {{ item.remarks }}
                            </td>
                          </tr>
                        </tbody> -->
                      </table>
                    </div>
              </div>
              <button @click="openAddressVerify()"  class="btn btn-secondary">match decision</button>
                </div>

               
                <div v-if="tab == 'namescreen'">
                  
                  <div class="market-content" v-if="!showScanButton">
                      <table >
                        <tbody >
                          <tr v-if="!isMatched">
                            <td>No results found</td>
                          </tr>
                        </tbody>
                        <tbody >
                          <tr class="d-flex justify-content-between" v-if="isMatched">
                            <td >Full name: {{memberName}}</td>
                            <td>
                              <button class="btn btn-secondary" @click="expand(scanId)">view result</button>
                            </td>
                          </tr>
                         
                        </tbody>
                      </table>
                    </div>
                    <button class="btn btn-main" @click="beginScan" :disabled="!init.FXCOMPLIANCE" v-if="!showScanButton">Begin Scan</button>
                    <div class="market-content" v-if="showScanButton">
                  
                    <table class="table">
                      <thead>
                      <tr>
                          <th>Match Type
                            <b-button v-b-tooltip.hover title="Used to determine how closely a watchlist entity must match an individual before being considered a match">
                          </b-button>
                          </th>
                          <th v-if="matchType == 'Close'">Match Rate %
                            <b-button v-b-tooltip.hover title="1-100%">
                          </b-button>
                          </th>
                          <th>Whitelist Policy
                            <b-button v-b-tooltip.hover title="Used for eliminating match results previously determined to not be a true a match">
                          </b-button>
                          </th>
                          <th>Country of Operation Policy:
                            <b-button v-b-tooltip.hover title="Used for eliminating match results previously determined to not be a true a match">
                          </b-button>
                          </th>
                         
                         
                         
                      </tr>
                    </thead>
                    <tbody >
                      <tr>
                        <td>
                          <select v-model="matchType" class="form-select form-control">
                            <option value="Exact">Exact</option>
                            <option value="Close">Close</option>
                          </select>
                        </td>
                        <td v-if="matchType == 'Close'">
                          <select v-model="closeMatchRateThreshold" class="form-select form-control">
                            <option value="">match rate</option>
                            <option v-for="rateOption in rates" :key="rateOption" :value="rateOption">{{ rateOption }}</option>
                    
                          </select>
                        </td>
                        <td>
                          <select v-model="whitelist" class="form-select form-control">
                            <option value="Apply">Apply</option>
                            <option value="Ignore">Ignore</option>
                           
                          </select>
                        </td>
                        <td>
                          <select v-model="addressPolicy" class="form-select form-control">
                            <option value="ApplyAll">Apply</option>
                            <option value="Ignore">Ignore</option>
                           
                          </select>
                        </td>
                       
                      </tr>
                    </tbody>
                    </table>
                    <table class="table">
                      <thead>
                      <tr>
                      
                          <th>Web Search
                            <b-button v-b-tooltip.hover title="Additional search for adverse media on the web using Google search engine.">
                          </b-button>
                          </th>
                          <th>FATF Jurisdiction Risk
                            <b-button v-b-tooltip.hover title="Understand the jurisdictional risk of an individual based on FATF guidelines.">
                          </b-button>
                          </th>
                          <th>Watchlist
                            <b-button v-b-tooltip.hover title="Scan against selected watchlists. This selection can be changed by the Compliance Officer in Administration > Organisations > List Access tab.">
                          </b-button>
                          </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr>
                       
                        <td>
                          <select v-model="includeWebSearch" class="form-select form-control">
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </td>
                        <td>
                          <select v-model="includeJurisdictionRisk" class="form-select form-control">
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </td>
                        <td>
                          POI | SIE - Terror, Organised Crime, Financial Crime, Narcotics Crime, Modern Slavery, Bribery and Corruption, Cyber Crime, Disqualified Directors, Other, Sanctions Lists (All), Law Enforcement, Regulatory Enforcement, Reputational Risk
                        </td>
                      </tr>
                    </tbody>
                    </table>
                   
                  </div>
                   
                  <div class="market-content" v-if="showScanButton">
                    <p style="color: red" v-if="status == '400' || showErrorMsg" >{{ errorMsg }}</p>
                    <table class="table" >
                      <thead>
                      <tr>
                          <th>Company Name**</th>
                          <th>Country of Operation: (optional)</th>
                          <th>Registration Number: (optional)</th>
                         
                        
                      </tr>
                    </thead>
                    <tbody >
                      <tr>
                        <td>
                          <input class="form-control" v-model="show.corpDetails.corpInfo.businessName" placeholder="required field" :disabled="isScanDone"/>
                        </td>
                        <td>
                          <div class=" mobile-container">
                            <div class="input-group mb-3">
                              <input type="text" class="form-control form-select" placeholder="click to select country" maxlength="2" id="formGroupExampleInput" v-model="show.corpDetails.corpInfo.issuanceCountry" v-on:click="toggleCountryListForAddress" :disabled="isScanDone">
                            </div>
                            <div class="country-list" v-if="showCountryForAddress">
                              <div class="row">
                                <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForAddress(l)" >
                                  <div>
                                    <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                                  </div>
                                  <div class="country-name">
                                    <span> {{ l.country }} </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <input class="form-control" v-model="registrationNumber" :disabled="acountry == 'PH'" placeholder="enter a valid registration number" />
                          
                        </td>
                        
                       
                      </tr>
                    </tbody>
                    </table>
                  
                    <div> 
                      <p v-if="showErrorMsg"> {{ errorMsg }}</p>
                    </div>
                    <div>
                    <button class="btn btn-cancel" @click="scanProfile()" :disabled="isLoading" v-if="!isScanDone">Scan</button>
                    <button class="btn btn-cancel"  @click="scanAnother()" v-if="isScanDone">Scan another</button>
                  </div>
                  </div>
                  
                  <br>
                </div>
                <div v-if="isLoading"> 
                  <div  class="loading-spinner"></div>
                    <p>This may take a while.</p>
                </div>
                
                <div class="market-content" v-if="isScanDone">
                  <p>Matched Entities: {{ noOfMatchedEntities }}</p>
                   <p>Message: {{ message }}</p>
                  <p v-if="enhancedProfilePrice">Profile Price: {{ enhancedProfilePrice }}</p>
                  <table v-for="data in kybResults" :key="data.id">
                        <tbody >
                          <tr >
                            <td>Address:</td>
                            <td>{{ data.address }}</td>
                            </tr>
                            <tr> 
                              <td>Company Code</td>
                              <td>{{ data.companyCode }}</td>
                            </tr>
                           <tr>
                            <td>Company Name:</td>
                            <td>{{ data.companyName }}</td>
                           </tr>
                           <tr>
                            <td>Company Number:</td>
                            <td>{{ data.companyNumber }}</td>
                           </tr>
                            <tr>
                              <td>Legal Status</td>
                              <td>{{ data.legalStatus }}</td>
                            </tr>
                            <tr>
                              <td>Legal Description</td>
                              <td>{{ data.legalStatusDescription }}</td>
                            </tr>
                        </tbody>
                        
                      </table>
                    
                      <!-- <table>
                        <thead >
                          <tr >
                            <th>Link
                              <button @click="viewScanResult(memberScan)">view more info</button>
                            </th>
                          </tr>
                        </thead>
                        <tbody >
                          <tr v-for="item in memberScan.webSearchResults" :key="item.id">
                            <td><a :href="item.link" target="_blank">{{item.link}}</a></td>

                          </tr>
                        </tbody>
                        
                      </table> -->
                </div>
<!---->
            </div>
          </div>
        
    <AddItem v-if="showAdd" @close="showAddItem()"></AddItem>
    <UploadWatchlist v-if="showUploadWatchlist" @close="openUploadWatchlist()"></UploadWatchlist>
    <UploadIdVerify v-if="showUploadIdVerify" @close="openUploadIdVerify()"></UploadIdVerify>
    <UploadAddressVerify v-if="showUploadAddressVerify" @close="openUploadAddressVerify()"></UploadAddressVerify>
    <UploadNamescreen v-if="showUploadNamescreen" @close="openUploadNamescreen()"></UploadNamescreen>
    <ViewUpload v-if="showUpload" @close="viewUpload(item)" :data="dataInfo"></ViewUpload>
    <Watchlist v-if="showWatchlistComment" @close="openWatchlist()" ></Watchlist>
    <AddressVerify v-if="showAddressComment" @close="openAddressVerify()"></AddressVerify>
    <IdVerify v-if="showIdComment" @close="openIdVerify()"></IdVerify>
	</div>
  
 
          
</template>
        
<script>
  import moment from 'moment';
  import Multiselect from 'vue-multiselect'
  import TheTopbar from '../components/TheTopbar.vue'
  import Header from '../components/Header.vue'
  import AddItem from '../components/modal/Documents/AddItem.vue'
  import UploadWatchlist from '../components/modal/Documents/UploadWatchlist.vue'
  import UploadIdVerify from '../components/modal/Documents/UploadIdVerify.vue'
  import UploadAddressVerify from '../components/modal/Documents/UploadAddressVerify.vue'
  import UploadNamescreen from '../components/modal/Documents/UploadNamescreen.vue'
  import ViewUpload from '../components/modal/Documents/ViewUpload.vue'
  import IndividualDueDiligence from '../view/IndividualDueDiligence.vue'
  import NamescreenResult from '../components/modal/Compliance/NamescreenResult.vue';
  import Watchlist from '../components/modal/Compliance/Watchlist.vue'
  import AddressVerify from '../components/modal/Compliance/AddressVerify.vue'
  import IdVerify from '../components/modal/Compliance/IdVerify.vue'


  export default {
      name: 'CompanyDueDiligence',
      components: {
        Multiselect,
        TheTopbar,
        Header,
        AddItem,
        UploadWatchlist,
        UploadIdVerify,
        UploadAddressVerify,
        UploadNamescreen,
        ViewUpload,
        IndividualDueDiligence,
        NamescreenResult,
        Watchlist,
        AddressVerify,
        IdVerify,
      },
      
       data: function() {
      return { 
          option: 'company',
          tab:'namescreen',
          showSettings: false,
          showWatchlistComment: false,
          showIdComment: false,
          showAddressComment: false,
          showNamescreenComment: false,
          showUpload: false,
          showUploadWatchlist: false,
          showUploadIdVerify: false,
          showUploadAddressVerify: false,
          showUploadNamescreen: false,
          watchlistComment:'',
          idComment: '',
          addressComment:'',
          namescreenComment:'',
          isApproved: false,
          showUpload: false,
          itemList: [],
          showAdd: false,
          showComment: false,
          showCountryForAddress: false,
          showCountry: false,
          country: 'PH',
          mobileCode: '63',
          list: {
                mobileCodes: []
          },
          // acountry: 'AR',
          rate:'',
          remarks:'',
          reviewId:'',
          details:[],
          detail:{},
          matchType:'Close',
          closeMatchRateThreshold:'80',
          whitelist:'Apply', 
          blankAddress:'ApplyDefaultCountry',
          countryOfOperation:'na',
          addressPolicy: 'ApplyAll',
          includeWebSearch: false,
          includeJurisdictionRisk: true,
          rates: Array.from({ length: 100 }, (v, i) => (i + 1).toString().padStart(2, '0')),
          days: Array.from({ length: 31 }, (v, i) => (i + 1).toString().padStart(2, '0')),
          months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          years: Array.from({ length: 100 }, (v, i) => new Date().getFullYear() - i),
          month:'',
          day:'',
          year:'',
          isWatchlistMatch:false,
          watchlistHistory:[],
          listed:[],
          idTypes:[],
          item:{
            fileFormat:''
          },
          watchlistFile:'WATCHLIST',
          isLoading: false,
          memberScan:'',
          scanId:'',
          scanData: {},
          scanHistory:[],
          isLoading: false,
          showExpand: false,
          companyName:'',
          registrationNumber:'',
          acountry:'na',
          idNumber:'null',

          profile:{},
          showScanButton: false,
          isScanDone: false,
          scan:{
            firstName:'',
            middleName:'',
            lastName: ''
          },
          scannedNames:'',
          memberName: '',
          isMatched: false,
          errorMsg:'',
          status:'',
          webSearchResults:[],
          scanId:'',
          clientId:"",
          showErrorMsg:false,
          kybResults:[],
          message:'',
          noOfMatchedEntities:'',
          enhancedProfilePrice:''

        
        

      
      }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
        listedOnly(){
          return this.watchlistHistory.filter(item => item.entityId === this.$route.params.entityId)
        },
       
        
     
      },
      methods:{
        handleSelectChange() {
          if (this.residences.includes('ApplyAll')) {
            this.residences = ['ApplyAll'];
          }
          if (this.residences.includes('Ignore')) {
            this.residences = ['Ignore'];
            return;
          }
          if(this.residences == '') {
            this.isDisabled = true
          }
          
        },
        optionSelected(option, id) {
          this.residences = option.value
          this.residenceValue.push(this.residences)
        },
        goToCompany(){
          window.location.href="/#/customer-company/" + this.$route.params.entityId
        },
        viewScanResult(memberScan){
          this.scanId = memberScan.scanId
          window.location.href="/#/name-matches/" + this.scanId
        },
        expand(scanId){
          this.scanId = scanId
          this.isLoading = true
        
          window.location.href="/#/name-matches/" + scanId
        },
        beginScan(){
          this.showScanButton = !this.showScanButton
        },
        showAddItem(){
         this.showAdd = !this.showAdd
        },
        goToCompliance(){
          window.location.href="/#/compliance"
        },
        openUploadWatchlist(result){
          this.watchlistComment = result
          this.showUploadWatchlist = !this.showUploadWatchlist
        },
        openUploadIdVerify(){
          this.showUploadIdVerify = !this.showUploadIdVerify
        },
        openUploadAddressVerify(){
          this.showUploadAddressVerify = !this.showUploadAddressVerify
        },
        openSettings(){
          this.showSettings = !this.showSettings
        },
        changeState(option) {
            this.option = option;
            localStorage.setItem("option", option);
          },
        opentab(tab) {
            this.tab = tab;
            localStorage.setItem("tab", tab);
        },
        getState() {
            let option = localStorage.getItem("option")
            if(option != "" || option != undefined) {
            this.option = option
            }
        },
        getTab() {
            let tab = localStorage.getItem("tab")
            if(tab != "" || tab != undefined) {
            this.tab = tab
            }
        },
        
        openUploadWatchlist(result){
          this.watchlistComment = result
          this.showUploadWatchlist = !this.showUploadWatchlist
        },
        openUploadIdVerify(){
          this.showUploadIdVerify = !this.showUploadIdVerify
        },
        openUploadAddressVerify(){
          this.showUploadAddressVerify = !this.showUploadAddressVerify
        },
        openUploadNamescreen(){
          this.showUploadNamescreen = !this.showUploadNamescreen
        },
        updateDate() {
          if (this.day && this.month && this.year) {
            // Do any additional validation or formatting if needed
            const formattedDate = `${this.day.toString().padStart(2, '0')} ${this.month.toString().padStart(2, '0')} ${this.year.toString()}`;
            console.log('Selected Date:', formattedDate);
          }
        },

        getListOfItems(){
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              url: '/ax/review/items'
              }
              this.axios(options)
              .then((response) => {
                this.itemList = response.data

               
              }).catch((err) => {
                  
              })
        },
      
        formatDate: function(date) {
          return moment(new Date(date)).format('MM/DD/YYYY hh:mm:ss A');
        },
          submitReview(item){
            this.reviewId = item.reviewId
            this.rate = item.rate
            this.remarks = item.remarks
           

            const params = {
              reviewId: this.reviewId,
              rate: this.rate,
              remarks: this.remarks,
              entityId: this.$route.params.entityId
            }

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/review/rate'
              }
              this.axios(options)
              .then((response) => {
                this.getResult();
              }).catch((err) => {
                  
              })
          },
          getResult(){
            const params = {
              entityId: this.$route.params.entityId
            }
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/review/items/',
              }
              this.axios(options) 
              .then((response) => {
                console.log(response.data)
            
                
              }).catch((err) => {
                  
              })
          },
          viewReview(item){
            this.reviewId = item.reviewId
            
            const params = {
              reviewId: this.reviewId
            }
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/review/rate/list',
              }
              this.axios(options) 
              .then((response) => {
                console.log(response.data)
                this.details = response.data

                // this.details.forEach((el,i) => {
                //   console.log(el)
                //   this.detail = el
                // })
                
              }).catch((err) => {
                  
              })
          },
         
          getWatchlist(){
                const options = {
                    method: 'GET',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: ' /ax/watchlist',
                    };

                  this.axios(options)
                  .then((response) => { 
                  this.watchlistHistory = response.data
               

                  // this.watchList.forEach((el, i) => {
                  //   console.log(el.entityId)
                  //   if(el.entityId == this.$route.params.entityId){
                  //     this.isWatchlistMatch = true
                  //     this.listed = el
                  //   }
                  // })
                
                })
          },
          getFiles(){
          const params = {
            entityId: this.$route.params.entityId
            }
           
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/customer/businessfile/' 
              }
              this.axios(options)
              .then((response) => {
                this.idTypes = response.data

               
              }).catch((err) => {
                  
              })
          },
            viewUpload(docData){
            this.dataInfo = docData
            this.showUpload = !this.showUpload
          },
            download(item){
            this.imageUrl = item.imageFile
            this.fileType = this.imageUrl.split('.').pop();

              const params = {
                entityId: this.$route.params.entityId
            }
              
            const options = {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId") 
                    }, 
                    params,
                    url: '/ax/customer/businessfile/image/' + item.businessfileId,
                    }
                    this.axios(options)
                    .then((response) => {
                      
                      
                    if(this.fileType === 'pdf'){
                      const link = document.createElement('a');
                      link.href = "data:application/pdf;base64," + response.data
                      link.download = 'file'; 
                      link.click();
                    }

                    if(this.fileType !== 'pdf'){
                      const link = document.createElement('a');
                      link.href = "data:image/png;base64," + response.data
                      link.download = 'file'; 
                      link.click();
                    }
                  
                    
                    }).catch((err) => {
                        
                    })
          },
         
          openWatchlist(){
            this.showWatchlistComment = !this.showWatchlistComment;
          },
          openIdVerify(){
            this.showIdComment = !this.showIdComment;
          },
          openAddressVerify(){
            this.showAddressComment = !this.showAddressComment;
          },
          openNamescreen(){
            this.showNamescreenComment = !this.showNamescreenComment;
          },
          addToWatchlist(){
            const params = {
              remarks: this.watchlistComment,
              entityId: this.$route.params.entityId

            }
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/watchlist/add',
              }
              this.axios(options)
              .then((response) => {
                // window.location.reload();
              }).catch((err) => {
              })
          
          },
          moveToWatchlist(){
             const params = {
		          eid: this.$route.params.entityId,
                  siteHost: "watchlist.ceza.org",
		        }

		        const data = Object.keys(params)
		          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
		          .join('&');
		   
                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/updateCustomerHost',
                    data
                    };

                this.axios(options)
                .then((response) => { 
                   
                  // window.location.reload();
                   
                })
            },
            scanAnother(){
              window.location.reload();
            },
            
            scanProfile(){
              this.isLoading = true
              let hasError = false;
              
                if(this.show.corpDetails.corpInfo.businessName == '' || this.show.corpDetails.corpInfo.businessName == undefined || this.show.corpDetails.corpInfo.businessName == null){
                  this.showErrorMsg = true
                  this.errorMsg = 'Company name is required.'
                  hasError = true
                  setTimeout(()=>{
                    this.showErrorMsg = false,
                    this.errorMsg = ''
                  }, 2000)
                }

                // if(this.acountry == 'PH'){
                //   this.showErrorMsg = false,
                //     this.errorMsg = "Please change country of operation."
                 
                // }
              
                
             
               if(!hasError){
        
                  let params = {
                      companyName: this.show.corpDetails.corpInfo.businessName,
                      entityNumber: this.$route.params.entityId,
                      registrationNumber: this.registrationNumber,
                      address: this.acountry,
                      countryCode: this.show.corpDetails.corpInfo.issuanceCountry,
                      matchType: this.matchType,
                      closeMatchRateThreshold: this.closeMatchRateThreshold,
                      whitelist: this.whitelist,
                      addressPolicy: this.addressPolicy,
                      blankAddress: this.blankAddress,
                      includeWebSearch: this.includeWebSearch,
                      includeJurisdictionRisk: this.includeJurisdictionRisk,
                      clientId: this.show.corpDetails.corpInfo.businessName

                    };

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/namescan/corpscan',
              }
              this.axios(options)
              .then((response) => {
                
                  this.isLoading = false
                  this.isScanDone = true
                  if(response.data.message == 'Search by registration number is not available for this country.'){
                    this.message = response.data.message
                    this.noOfMatchedEntities = '0'
                  }

                  if(response.data.kybScanResult){
                    this.message = response.data.metadata.message
                    this.noOfMatchedEntities = response.data.kybScanResult.companyResults.length
                    this.enhancedProfilePrice = response.data.kybScanResult.enhancedProfilePrice
                    this.kybResults = response.data.kybScanResult.companyResults
                  }
                  
                  else {
                    this.message = response.data.metadata.message
                    this.noOfMatchedEntities = response.data.matchedNumber
                   
                  }
                  
                  
                  
                  

                 
                if(response.data.status == 400){
                  // this.errorMsg = response.data.errors
                  console.log(response.data.errors)
                  response.data.errors.forEach((error) => {

                     
                      });
                  setTimeout(()=>{
                    this.errorMsg = ""
                  }, 2000)
                }
                
                // setTimeout(()=>{
                //      window.location.reload();
                //   }, 2000)
              }).catch((error) => {
                this.errorMsg = response.data.title
              })
             }
            },
          
            getScanHistory(){
              this.isMatched = false
              this.getProfile();
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              url: ' /ax/namescan/history'
              }
              this.axios(options)
              .then((response) => {
                this.scanHistory = response.data
                

                  let elementName = ''
                  let elementId = ''
                  this.scanHistory.forEach((el, i) => {
                    this.scan = el
                   
                    if( el.middleName == null ){
                        this.scan.middleName = ""
                  }
                    
                    elementName = this.scan.firstName + this.scan.middleName + this.scan.lastName
                    elementId = this.scan.scanId
                    
                    if(this.memberName.toLowerCase() === elementName.toLowerCase()){
                      this.isMatched = true
                      this.scannedNames = elementName
                      this.scanId = elementId
                      return;
                    } 
                  })
              }).catch((err) => {
                  
              })
              },
              getProfile() {
              const params = {
                eid: this.$route.params.entityId
              }
              const data = Object.keys(params)
                        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                        .join('&');

              const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'vmiadmin-authz': localStorage.getItem("tokenId") 
                }, 
                data,
                url: '/ax/getCustomerProfile/',
                }
                this.axios(options)
                .then((response) => {
                  
                  this.show.profile = response.data;
                  this.show.corpDetails = response.data.corpDetails

                  if( response.data.middleName == null ){
                          this.show.profile.middleName = ""
                  }
                 

                  this.memberName = this.show.profile.firstName + this.show.profile.middleName + this.show.profile.lastName
                 
                
                }).catch((err) => {
                    
                })
            },
           toggleCountryListForAddress(){
            this.showCountryForAddress = !this.showCountryForAddress;
          },
          selectCountryForAddress: function(c) {
            this.showCountryForAddress = false;
            this.show.corpDetails.corpInfo.issuanceCountry = c.countryCode
           
          },
         
              
          getMobileCodes: function() {
          this.axios.get('/assets/js/mobilecodes.json')
              .then((response) => {
              this.list.mobileCodes = response.data;
              }).catch((err) => {
              console.log(err);

              })
          },
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
              this.axios(options)
              .then((response) => {
              
              }).catch((err) => {
                 

                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
      beforeMount(){
        this.getProfile();
      },
      mounted(){
          this.sessionCheck();
          this.getListOfItems();
          this.getMobileCodes();
          this.getWatchlist();
          this.getFiles();
          this.getTab();
          this.getResult();
         
          
      }
  }
</script>
        
<style scoped lang="scss">
    * {
    box-sizing: border-box;
  }
  
  /* Create two equal columns that floats next to each other */
  .column {
    float: left;
    width: 50%;
    padding: 10px;
  
  }
  .white{
    color: white !important;
  }
  .form-control-short{
    border-radius: 10px;
    padding: 10px;
  }
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }
  .checkbox label{
    font-size: 12px !important;
  }
.header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }

  
  .market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
   
  .content-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    justify-content: center !important;
    /* max-width: 1000px; */
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #ffffff;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
    
  }
 
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }

  .enhanced{
    margin-top: 2em;
  }
.btn-main{
  width: 100%;
  border: 1px solid beige !important;
  color: white !important;
}
.btn-main.active{
  background: #007bff !important;
}

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}
.btn .btn-checkbox{
  padding: 0px !important;
  margin: 0px !important;
}
.btn-checkbox .checkbox{
  width: 20px !important;
}
label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}
.btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }
  .btn-cancel:hover{
        float: right;
        background-color: #007bff;
        color: white;
 }
   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
            color: white !important;
          }
   

  .flag-list-img {
    width: 33px;
  }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 200px;
  border-radius: 10px;

  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}




   

.icon img{
  width: 2rem;
}
.card{
  background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
  );
    box-shadow:0 0 30px rgba(0,0,0,0.3);
    border: 1px solid transparent;
    border-radius: 12px;
    width: 100%;
    height: auto;
    text-align: center;
    color: white;
}


.verified{
  background-color: green;
  color: white;
}
.submitted{
  background-color: green !important;
  color: white;
}
.notSubmitted{
  background-color: red;
  
}
  
 
  
    .progress-bars {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    /* margin-bottom: 20px; */
  }
  
  .progress-bar {
    width: 100px;
    height: 50px;
    background-color: lightgray;
    background-color: #ffffff !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
  }
  
  .active {
    background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;;
    color: white !important;
  }

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }

  .progress-bar {
    width: 80px;
    height: 50px;
    background-color: lightgray;
    background-color: #ffffff !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
    
  }
  
}
@media (max-width: 1280px) {

  
}
  
  label {
    margin-right: 5px;
  }
  

.btn-card-upload{
  width: 100%;
  height: auto;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: gray !important;
  color: black;
  display: flex;
  justify-content: space-between;
 
}
.text-white, label, span{
  color: white !important;
}
.indicator img{
  width: 1rem;
}
.indicator-progress img{
  width: 1rem;
}
.loading-spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #007BFF; /* Change the color to your preferred color */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

 

  .button-content{
    overflow-y: auto;
    background: rgb(141, 138, 138);
    border-radius: 10px;;
    width: auto;
    height: auto !important;
    max-height: 500px;
    z-index: 9999;
    position: absolute;
    padding: 10px;
     color: black;
  }
  .multiselect__content-wrapper{
    background: rgb(141, 138, 138) !important;
    position: absolute;
    display: block;
    /* background: #fff; */
    width: 100%;
    /* max-height: 240px; */
    /* overflow: auto; */
    /* border: 1px solid #e8e8e8; */
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 50;

  }
  .multiselect__content{
    max-height: 500px !important;
    display: block !important;
  }
   .interest .span{
    float: left;
    padding: 3px 5px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    max-height: 500px;
    z-index: 9999;
    &:hover{
      background-image:linear-gradient(to right, #ff8445, #007bff, #ad09cf);
      border-radius: 5px;
      color: white !important;
    }
   }
</style>
