<template>
  <div class="backdrop">
    <div class="b-modal">
        <div class="modal-header text-right">
            <h5 class="m-2 text-white">Upload FULL License</h5>
            <p @click="closeModal" class="x">x</p>
        </div>
      <div class="modalBody">
      
           <div v-if="step == '3'" class="mt-5">
                <p style="color: white">Upload successful.</p>
                <p>
                <button class="btn btn-secondary submit" @click="done">Done</button>
                </p>
            </div>
            

         <form v-on:submit.prevent="uploadImage($event)" v-if="step == '1'">
             <label for="idType"></label>
             <label class="form-check-label" style="color: white" for="poa">
              Issue a FULL License
             </label>
                <div class="payment-container text-left d-block">
                <form class="d-flex justify-content-between">
                 <p>Accepted file format: PDF, PNG, JPG, JPEG. Max: 4MB</p>
                  <p v-if="fileSizeExceeded" style="color: rgb(189, 64, 64);">File size exceeds the limit. Max: 4MB</p>
                  
                </form>
                </div>
                
            <!-- <div class="buttonWrapper" >
                <div class="dropbox text-white">
                  <input type="file" ref="fileInput" @change="handleFileSelect">
                  <p class="text-white">Selected file: {{ selectedFile }}</p>
                </div>
            </div> -->
            <p>File:</p>
            <b-form-file v-model="importFile.file" 
            :state="Boolean(importFile.file)" 
            placeholder="Choose a file or drop it here.">
            </b-form-file>

            <div class=""> 
                <label class="text-white">Expiration Date:</label>
                 <table >
                  <tbody>
                    <td >DD:
                      <select v-model="day" @change="updateDate" class="form-control form-select" >
                        <option value=""></option>
                        <option v-for="dayOption in days" :key="dayOption" :value="dayOption">{{ dayOption }}</option>
                      </select>
                    </td>
                    <td >MM:
                      <select v-model="month" @change="updateDate" class="form-control form-select" >
                        <option value=""></option>
                        <option v-for="(monthOption, index) in months" :key="index" :value="index + 1">{{ monthOption }}</option>
                      </select>
                    </td>
                    <td >YYYY:
                      <select v-model="year" @change="updateDate" class="form-control form-select" >
                        <option value=""></option>
                        <option v-for="yearOption in years" :key="yearOption" :value="yearOption">{{ yearOption }}</option>
                      </select>
                    </td>
                  </tbody>
               </table>
               
               </div>
               <div>
                <label class="text-white">License Number:</label>
               <input v-model="licenseNumber" class="form-control"/>
               </div>
               <div> 
                <label class="text-white">Principal Licensee:</label>
               <input v-model="principalLicensee" class="form-control"/>
               </div>
            <div>
                <button type="submit" class="btn btn-secondary" id="saveBtn" :disabled="isLoading">Submit</button>
                <span class="text-white" v-if="isLoading">Submitting....</span>
            </div>
            

            
        </form>
        
         
       
          <!--FAILED-->
          <div v-if="isFailed">
            <h2 class="red">Upload failed.</h2>
            <p v-if="fileSizeError" style="color: red">{{ fileSizeError }}</p>
            <p  class="red">
              <a href="javascript:void(0)" @click="closeModal()">Try again</a>
            </p>
          </div>
          <div class="text-center">
            <img class="iconImg" >
         </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    components: {

    },
    data: function() {
        return {
          applicationStep:'8',
          uploadError:'',
          fileSizeError: null,
          referenceFileId: '1',
          expirationDate:'',
          description:'FULL-LICENSE',
            step:'1',
            idType:'Others',
            fileType:'FULL-LICENSE',
            selectedFile:'',
            selectedFileBack:'',
            idNumber:'111',
            uploadFieldName: 'imageFile',
            uploadFieldNameBack: 'imageFile',
            isSuccess: false,
            bothSuccess: false,
            isFailed: false,
            isLoading: false,
            maxSizeInBytes: 4 * 1024 * 1024, // Set your maximum file size limit in bytes (1MB in this example)
            fileSizeExceeded: false,
            importFile:{
              file: null
            },
            principalLicensee:'',
            licenseNumber:'',
            response:'',
            month:'',
            day:'',
            year:'',
            days: Array.from({ length: 31 }, (v, i) => (i + 1).toString().padStart(2, '0')),
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            years: Array.from({ length: 100 }, (v, i) => new Date().getFullYear() + i),
            errors: {
                profile: {
                    show:false,
                    msg: ""
                },
            },
            
        }
    },

    computed: {
     
    },

    mounted() {
     
    },

    watch: {
      
    },

    methods: {
      uploadNow(){
        this.step = '1'
      },
      updateDate() {
          if (this.month && this.day && this.year) {
            // Do any additional validation or formatting if needed
            const formattedDate = `${this.month.toString().padStart(2, '0')} ${this.day.toString().padStart(2, '0')} ${this.year.toString()}`;
            console.log('Selected Date:', formattedDate);
          }
        },
      handleFileSelect() {
          // const file = this.$refs.fileInput.files[0];
          // this.selectedFile = file ? file.name : '';

          this.fileSizeExceeded = false; // Reset the flag
            setTimeout(()=>{
              this.fileSizeExceeded = false 
            }, 2000)

            const selectedFile = event.target.files[0];
            if (selectedFile) {
              if (selectedFile.size > this.maxSizeInBytes) {
                this.fileSizeExceeded = true;
                // You can also reset the input or take other actions here.
                event.target.value = null; // Clear the selected file
              } else {
                // File size is within the limit, you can proceed with the upload.
                // You may want to emit an event or handle the file upload logic here.
              }
            }
          },
          handleFileSelectBack() {
          const file = this.$refs.fileInput.files[0];
          this.selectedFileBack = file ? file.name : '';
          },

        closeModal(){
            this.$emit("close")
           
        },

        done(){
          this.$emit("close");
         
        },
        upload(){
          const params = {
              imageFile: this.importFile.file,
              description: this.description,
              entityId: this.$route.params.entityId,
              fileType: this.fileType,
              referenceFileId: this.referenceFileId
              }


              let data = new FormData();
                    data.append('imageFile', this.importFile.file);
                    data.append('entityId',this.$route.params.entityId); 
                    data.append('description',this.description); 
                    data.append('fileType',this.fileType); 
                    data.append('referenceFileId',this.referenceFileId); 
                    

            
            const options = {
                method: 'POST',
                headers: {
                      'content-type': 'image/png',
                      'vmiadmin-authz': localStorage.getItem("tokenId") 
                },
                data,
                url: '/ax/customer/upload/businessfile',
            };

           this.axios(options).then(
                response => {
                    console.log(response.request.status)
                    this.response = response.request.status
                    this.isLoading = true 
                    
                    if(this.response = 200){
                     
                        setTimeout( () => {
                            this.isLoading = false
                            this.bothSuccess=true 
                            this.step = '3'
                        
                            
                        },1000)
                    } else {
                        this.isFailed = true
                        setTimeout(()=>{
                        this.isFailed = false
                      },2000)
                    }
                }
              ).catch((err) => {
                  if(this.response = 500){
                      this.isFailed = true
                      setTimeout(()=>{
                        this.isFailed = false
                      },2000)
                  }

          })
        },
         uploadImage(event) {
            console.log(this.importFile.file)
         
            this.isLoading = true;
          

          let formData = new FormData();
              formData.append('file', this.importFile.file);
              formData.append('entityId',this.$route.params.entityId); 
              formData.append('step', this.applicationStep); 
              formData.append('licenseNumber', this.licenseNumber); 
              formData.append('principalLicensee', this.principalLicensee); 
              formData.append('expirationDate',`${this.month.toString().padStart(2, '0')}/${this.day.toString().padStart(2, '0')}/${this.year.toString()}`); 
                          
              this.axios.post('/ax/businessfile/confirm/nft',
                formData,
                {
                  headers: {
                  'Content-Type': 'multipart/form-data',
                   'vmiadmin-authz': localStorage.getItem("tokenId") 
                  }
                }).then((response) => { 
                  this.isLoading = false 
                    console.log(response.request.status)

                    this.response = response.request.status
                    this.upload();
                    
                    // if(this.response = 200){
                     
                    //     setTimeout( () => {
                    //         this.isLoading = false
                    //         this.bothSuccess=true 
                    //         this.step = '3'
                        
                            
                    //     },1000)
                    // } else {
                    //   this.isLoading = false 
                    //     this.isFailed = true
                    //     setTimeout(()=>{
                    //     this.isFailed = false
                    //   },2000)
                    // }
                }
              ).catch((err) => {
                  if(this.response = 500){
                    
                      this.isFailed = true
                      setTimeout(()=>{
                        this.isLoading = false 
                        this.isFailed = false
                      },2000)
                  }
                  
                })
                        
        },
        uploadImageBack(event) {
          

          const params = {
              poidImageFile: this.selectedFileBack,
              poidType: this.idType,
              poidDescription: this.description,
              poidNumber: this.idNumber,
              }


         let data = new FormData();
                    data.append('poidImageFile', this.$refs.fileInput.files[0]);
                    data.append('poidType', this.idType);
                    data.append('poidDescription',this.description); 
                    data.append('poidNumber', this.idNumber);
                    

            
            const options = {
                method: 'POST',
                headers: {
                      'content-type': 'image/png',
                      'blox-authz': localStorage.getItem("tokenId") 
                },
                data,
                url: '/cx/uploadpoid',
            };

           this.axios(options).then(
                response => {
                    console.log(response.request.status)
                    this.response = response.request.status
                    this.isLoading = true 
                    if(this.response = 200){
                        
                        setTimeout( () => {
                            this.isLoading = false
                            this.bothSuccess=true 
                            this.step = '3'

                        },3000)

                        
                    } else {
                        this.isFailed = true
                        setTimeout(()=>{
                        this.isFailed = false
                      },2000)
                    }
                    


                }
            ).catch((err) => {
                  if(this.response = 500){
                      this.isFailed = true
                      setTimeout(()=>{
                        this.isFailed = false
                      },2000)
                  }

          })
        },
      
    }
}
</script>

<style scoped lang=scss>
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);

}
.b-modal {
    width: 700px;
    height: auto;
    margin: 50px auto;
    background-color: #ffffff;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 99;
    overflow-x: hidden !important;
    color:white;
    max-height: calc(100vh - 110px);
    overflow-y: auto;
}

.modal-header {
  background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    margin-right:0px;
    font-size:25px;
    display:flex;
    justify-content:space-between;
}

.modal-title{
    margin: 0 auto;
    color:white;
}

.modalBody {
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    width:100%;
    padding:50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
}

.buttonWrapper {
    width:100%;
    margin-top: 10px;
    padding-top: 15px;
}

.x {
    cursor:pointer;
}
.red{
    color: red;
}




    @media screen and (max-width: 1100px) {
    
          
    }
   @media screen and (max-width: 800px) {
     
  }

    @media screen and (max-width: 600px) {
       
        
    }

    @media screen and (min-width: 280px) and (max-width: 420px) {

       .b-modal {
            width: 350px;
            height: auto;
            margin-top: 50px;
            background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
          background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
            width:100%;
            padding: 20px 10px;
            border-radius: 10px;
        }
        

  
      


    }

    @media screen and (min-width: 480px) and (max-width: 580px) {
        .b-modal {
                width: 405px;
        }

    
     
    }
    .wrapper{
      display: flex;
       padding: 2em;
      justify-content: center !important;
      margin: 0 auto !important;
  }
 
    .product-grid {
      display: flex;
     
    }

  .product-card {
      background-image: linear-gradient(
              180deg,
            #10184d 29%,
            #00103d 59%,
              #010c2a 88%
      );
      box-shadow:0 0 30px rgba(0,0,0,0.3);
      border: 1px solid transparent;
      border-radius: 12px;
      width: 7rem;
      height: auto;
      text-align: center;
      margin: 10px !important;
      color: white;
      cursor: none;
  
  }


  .product-image {
    padding: 10px 0px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      margin: 0 auto;
      background-color: none !important;
    width: 100%;
    max-width: 80px;
    height: auto;
    margin-bottom: 10px;
  }
</style>