<template>
	<div class="main">
	<div class="main-container" >
    <div v-if="isLoading" class="loading-spinner"></div>
              <div class="">
                <h5 class="text-white">DOCUMENTARY REQUIREMENTS CHECKLIST</h5>

                <div class="wrapper">
                  <div class="progress-bars" v-b-tooltip.hover title="A yellow circle will appear once the applicant has submitted the specific document">
                   
                    
                    <div >
                      <div class="progress-bar">Articles of Incorporation and By-laws
                        <button v-if="!isAoi" class="btn-main" @click="uploadAoi">upload</button>
                        <span class="indicator btn-disabled" v-if="isAoi">
                          <img :src="require('../assets/circle/current.png')"/> </span>
                      </div>
                    </div>
                    <div >
                      <div class="progress-bar">Certificate of Incorporation
                        <button v-if="!isCoi" class="btn-main" @click="uploadCoi">upload</button>
                        <span class="indicator btn-disabled" v-if="isCoi">
                          <img :src="require('../assets/circle/current.png')"/> </span>
                      </div>
                    </div>
                    
                    <div>
                      <div class="progress-bar" >General Information Sheet
                        <button v-if="!isGis" class="btn-main" @click="uploadGis">upload</button>
                        <span class="indicator btn-disabled" v-if="isGis">
                          <img :src="require('../assets/circle/current.png')"/> </span>
                      </div>
                    </div>
                    <div >
                      <div class="progress-bar" >Audited Financial Statement certified by CPA
                        <button v-if="!isAfs" class="btn-main" @click="uploadAfs">upload</button>
                        <span class="indicator btn-disabled" v-if="isAfs">
                          <img :src="require('../assets/circle/current.png')"/> </span>
                      </div>
                    </div>
                    
                    <div>
                      <div class="progress-bar">Board Resolution
                        <button v-if="!isBoardReso" class="btn-main" @click="uploadBoardReso">upload</button>
                        <span class="indicator btn-disabled" v-if="isBoardReso">
                          <img :src="require('../assets/circle/current.png')"/> </span>
                      </div>
                     
                    </div>
                    <div>
                      <div class="progress-bar">Acceptance of Appointment
                        <button v-if="!isAppointment" class="btn-main" @click="uploadAcceptanceAppt">upload</button>
                        <span class="indicator btn-disabled" v-if="isAppointment">
                          <img :src="require('../assets/circle/current.png')"/> </span>
                      </div>
                     
                    </div>
                    <div>
                      <div class="progress-bar" >Certificate of Name Change
                        <button v-if="!isNameChange" class="btn-main" @click="uploadNameChange">upload</button>
                        <span class="indicator btn-disabled" v-if="isNameChange">
                          <img :src="require('../assets/circle/current.png')"/> </span>
                      </div>
                      
                    </div>
                    <div>
                      <div class="progress-bar">Income Tax Return
                        <button v-if="!isITR" class="btn-main" @click="uploadItr">upload</button>
                        <span class="indicator btn-disabled" v-if="isITR">
                          <img :src="require('../assets/circle/current.png')"/> </span>
                      </div>
                     
                    </div>
                    <div>
                      <div class="progress-bar">Internal Business Rules and Regulations
                        <button v-if="!isRules" class="btn-main" @click="uploadIbrr">upload</button>
                        <span class="indicator btn-disabled" v-if="isRules">
                          <img :src="require('../assets/circle/current.png')"/> </span>
                      </div>
                     
                    </div>
                    <div >
                      <div class="progress-bar">Money Laundering and Terrorist Financing Prevention Program (MTPP)
                        <button v-if="!isMtpp" class="btn-main" @click="uploadMtpp">upload</button>
                        <span class="indicator btn-disabled" v-if="isMtpp">
                          <img :src="require('../assets/circle/current.png')"/> </span>
                      </div>
                    </div>
                    
                    <div>
                      <div class="progress-bar" >Internal AML/CFT/KYC Policies
                        <button v-if="!isPolicies" class="btn-main" @click="uploadAml">upload</button>
                        <span class="indicator btn-disabled" v-if="isPolicies">
                          <img :src="require('../assets/circle/current.png')"/> </span>
                      </div>
                      
                    </div>
                    <div>
                      <div class="progress-bar">Customer Protection Program
                        <button v-if="!isCPP" class="btn-main" @click="uploadCpp">upload</button>
                        <span class="indicator btn-disabled" v-if="isCPP">
                          <img :src="require('../assets/circle/current.png')"/> </span>
                      </div>
                    
                    </div>
               

                    
                    
                    <!-- <div >
                      <div class="progress-bar">Share Registers
                        <span class="indicator btn-disabled" v-if="isShareRegisters">
                          <img :src="require('../assets/circle/current.png')"/> </span>
                      </div>
                    </div>
                 
                    
                    
                  
                    <div >
                      <div class="progress-bar" >Treasurer’s Affidavit supported by proof of payment of subscribed capital
                        <span class="indicator btn-disabled" v-if="isTreasurersAffidavit">
                          <img :src="require('../assets/circle/current.png')"/> </span>
                      </div>
                    </div> -->
                    
                  </div>
                 
                </div>
               
              <ul class="btn-group-card" >
                <form>
                 
                  <li class="d-flex" v-for="doc in applicationFormOnly" :key="doc.id">
                    <button class="btn-card-upload" disabled>
                      <h6>{{doc.description}} 
                        <span class="indicator" v-if="doc.status == 'REJECT'"  >
                        <img :src="require('../assets/circle/rejected.png')"/> 
                      </span>
                      <span class="indicator" v-if="doc.status == 'ACCEPTED'" >
                        <img :src="require('../assets/circle/approved.png')"/> 
                      </span>
                      <span  v-if="doc.status != 'SUBMITTED'" >{{ doc.processedBy }} on {{ formatDate(doc.updateDate) }}</span>
                      
                    </h6>
                    <div>
                       <button class="btn btn-view"  @click.prevent="viewUpload(doc)" v-b-tooltip.hover title="Click to view and action the document submitted by the applicant" >view</button>
                     
                    </div>
                    </button>
                    
                  </li>
                  <li class="d-flex" v-for="doc in probePersonalOnly" :key="doc.id">
                    <button class="btn-card-upload" disabled>
                      <h6>{{doc.description}} 
                        <span class="indicator" v-if="doc.status == 'REJECT'"  >
                        <img :src="require('../assets/circle/rejected.png')"/> 
                      </span>
                      <span class="indicator" v-if="doc.status == 'ACCEPTED'" >
                        <img :src="require('../assets/circle/approved.png')"/> 
                      </span>
                      <span  v-if="doc.status != 'SUBMITTED'" >{{ doc.processedBy }} on {{ formatDate(doc.updateDate) }}</span>
                      
                    </h6>
                    <div>
                       <button class="btn btn-view"  @click.prevent="viewUpload(doc)" v-b-tooltip.hover title="Click to view and action the document submitted by the applicant" >view</button>
                     
                    </div>
                    </button>

                  </li>

                  <li class="d-flex" v-for="doc in probeCompanyOnly" :key="doc.id">
                    <button class="btn-card-upload" disabled>
                      <h6>{{doc.description}} 
                        <span class="indicator" v-if="doc.status == 'REJECT'"  >
                        <img :src="require('../assets/circle/rejected.png')"/> 
                      </span>
                      <span class="indicator" v-if="doc.status == 'ACCEPTED'" >
                        <img :src="require('../assets/circle/approved.png')"/> 
                      </span>
                      <span  v-if="doc.status != 'SUBMITTED'" >{{ doc.processedBy }} on {{ formatDate(doc.updateDate) }}</span>
                      
                    </h6>
                    <div>
                       <button class="btn btn-view"  @click.prevent="viewUpload(doc)" v-b-tooltip.hover title="Click to view and action the document submitted by the applicant" >view</button>
                     
                    </div>
                    </button>

                  </li>

                  <li class="d-flex" v-for="doc in investCommitOnly" :key="doc.id">
                    <button class="btn-card-upload" disabled>
                      <h6>{{doc.description}} 
                        <span class="indicator" v-if="doc.status == 'REJECT'"  >
                        <img :src="require('../assets/circle/rejected.png')"/> 
                      </span>
                      <span class="indicator" v-if="doc.status == 'ACCEPTED'" >
                        <img :src="require('../assets/circle/approved.png')"/> 
                      </span>
                      <span  v-if="doc.status != 'SUBMITTED'" >{{ doc.processedBy }} on {{ formatDate(doc.updateDate) }}</span>
                      
                    </h6>
                    <div>
                       <button class="btn btn-view"  @click.prevent="viewUpload(doc)" v-b-tooltip.hover title="Click to view and action the document submitted by the applicant" >view</button>
                     
                    </div>
                    </button>

                  </li>
                  <li class="d-flex" v-for="doc in aoiOnly" :key="doc.id">
                    <button class="btn-card-upload" disabled>
                      <h6>{{doc.description}} 
                        <span class="indicator" v-if="doc.status == 'REJECT'"  >
                        <img :src="require('../assets/circle/rejected.png')"/> 
                      </span>
                      <span class="indicator" v-if="doc.status == 'ACCEPTED'" >
                        <img :src="require('../assets/circle/approved.png')"/> 
                      </span>
                      <span  v-if="doc.status != 'SUBMITTED'" >{{ doc.processedBy }} on {{ formatDate(doc.updateDate) }}</span>
                      
                    </h6>
                    <div>
                      <button class="btn btn-view"  @click.prevent="viewUpload(doc)" v-b-tooltip.hover title="Click to view and action the document submitted by the applicant" >view</button>
                     
                    </div>
                    </button>

                  </li>
                  <li class="d-flex" v-for="doc in coiOnly" :key="doc.id">
                    <button class="btn-card-upload" disabled>
                      <h6>{{doc.description}} 
                        <span class="indicator" v-if="doc.status == 'REJECT'"  >
                        <img :src="require('../assets/circle/rejected.png')"/> 
                      </span>
                      <span class="indicator" v-if="doc.status == 'ACCEPTED'" >
                        <img :src="require('../assets/circle/approved.png')"/> 
                      </span>
                      <span  v-if="doc.status != 'SUBMITTED'" >{{ doc.processedBy }} on {{ formatDate(doc.updateDate) }}</span>
                      
                    </h6>
                    <div>
                       <button class="btn btn-view"  @click.prevent="viewUpload(doc)" v-b-tooltip.hover title="Click to view and action the document submitted by the applicant" >view</button>
                     
                    </div>
                    </button>

                  </li>
                  <li class="d-flex" v-for="doc in gisOnly" :key="doc.id">
                    <button class="btn-card-upload" disabled>
                      <h6>{{doc.description}} 
                        <span class="indicator" v-if="doc.status == 'REJECT'"  >
                        <img :src="require('../assets/circle/rejected.png')"/> 
                      </span>
                      <span class="indicator" v-if="doc.status == 'ACCEPTED'" >
                        <img :src="require('../assets/circle/approved.png')"/> 
                      </span>
                      <span  v-if="doc.status != 'SUBMITTED'" >{{ doc.processedBy }} on {{ formatDate(doc.updateDate) }}</span>
                      
                    </h6>
                    <div>
                       <button class="btn btn-view"  @click.prevent="viewUpload(doc)" v-b-tooltip.hover title="Click to view and action the document submitted by the applicant" >view</button>
                      
                    </div>
                    </button>
                    
                  </li>
                  <li class="d-flex" v-for="doc in nameChangeOnly" :key="doc.id">
                    <button class="btn-card-upload" disabled>
                      <h6>{{doc.description}} 
                        <span class="indicator" v-if="doc.status == 'REJECT'"  >
                        <img :src="require('../assets/circle/rejected.png')"/> 
                      </span>
                      <span class="indicator" v-if="doc.status == 'ACCEPTED'" >
                        <img :src="require('../assets/circle/approved.png')"/> 
                      </span>
                      <span  v-if="doc.status != 'SUBMITTED'" >{{ doc.processedBy }} on {{ formatDate(doc.updateDate) }}</span>
                      
                    </h6>
                    <div>
                       <button class="btn btn-view"  @click.prevent="viewUpload(doc)" v-b-tooltip.hover title="Click to view and action the document submitted by the applicant" >view</button>
                     
                    </div>
                    </button>

                  </li>
                  <li class="d-flex" v-for="doc in directorsCertOnly" :key="doc.id">
                    <button class="btn-card-upload" disabled>
                      <h6>{{doc.description}} 
                        <span class="indicator" v-if="doc.status == 'REJECT'"  >
                        <img :src="require('../assets/circle/rejected.png')"/> 
                      </span>
                      <span class="indicator" v-if="doc.status == 'ACCEPTED'" >
                        <img :src="require('../assets/circle/approved.png')"/> 
                      </span>
                      <span  v-if="doc.status != 'SUBMITTED'" >{{ doc.processedBy }} on {{ formatDate(doc.updateDate) }}</span>
                      
                    </h6>
                    <div>
                       <button class="btn btn-view"  @click.prevent="viewUpload(doc)" v-b-tooltip.hover title="Click to view and action the document submitted by the applicant" >view</button>
                     
                    </div>
                    </button>

                  </li>
                  <li class="d-flex" v-for="doc in boardResoOnly" :key="doc.id">
                    <button class="btn-card-upload" disabled>
                      <h6>{{doc.description}} 
                        <span class="indicator" v-if="doc.status == 'REJECT'"  >
                        <img :src="require('../assets/circle/rejected.png')"/> 
                      </span>
                      <span class="indicator" v-if="doc.status == 'ACCEPTED'" >
                        <img :src="require('../assets/circle/approved.png')"/> 
                      </span>
                      <span  v-if="doc.status != 'SUBMITTED'" >{{ doc.processedBy }} on {{ formatDate(doc.updateDate) }}</span>
                      
                    </h6>
                    <div>
                       <button class="btn btn-view"  @click.prevent="viewUpload(doc)" v-b-tooltip.hover title="Click to view and action the document submitted by the applicant" >view</button>
                     
                    </div>
                    </button>

                  </li>
                  
                 
                  <li class="d-flex" v-for="doc in afsOnly" :key="doc.id">
                    <button class="btn-card-upload" disabled>
                      <h6>{{doc.description}} 
                        <span class="indicator" v-if="doc.status == 'REJECT'"  >
                        <img :src="require('../assets/circle/rejected.png')"/> 
                      </span>
                      <span class="indicator" v-if="doc.status == 'ACCEPTED'" >
                        <img :src="require('../assets/circle/approved.png')"/> 
                      </span>
                      <span  v-if="doc.status != 'SUBMITTED'" >{{ doc.processedBy }} on {{ formatDate(doc.updateDate) }}</span>
                      
                    </h6>
                    <div>
                       <button class="btn btn-view"  @click.prevent="viewUpload(doc)" v-b-tooltip.hover title="Click to view and action the document submitted by the applicant" >view</button>
                     
                    </div>
                    </button>

                  </li>
                  <li class="d-flex" v-for="doc in itrOnly" :key="doc.id">
                    <button class="btn-card-upload" disabled>
                      <h6>{{doc.description}} 
                        <span class="indicator" v-if="doc.status == 'REJECT'"  >
                        <img :src="require('../assets/circle/rejected.png')"/> 
                      </span>
                      <span class="indicator" v-if="doc.status == 'ACCEPTED'" >
                        <img :src="require('../assets/circle/approved.png')"/> 
                      </span>
                      <span  v-if="doc.status != 'SUBMITTED'" >{{ doc.processedBy }} on {{ formatDate(doc.updateDate) }}</span>
                      
                    </h6>
                    <div>
                       <button class="btn btn-view"  @click.prevent="viewUpload(doc)" v-b-tooltip.hover title="Click to view and action the document submitted by the applicant" >view</button>
                     
                    </div>
                    </button>

                  </li>
                  <li class="d-flex" v-for="doc in policiesOnly" :key="doc.id">
                    <button class="btn-card-upload" disabled>
                      <h6>{{doc.description}} 
                        <span class="indicator" v-if="doc.status == 'REJECT'"  >
                        <img :src="require('../assets/circle/rejected.png')"/> 
                      </span>
                      <span class="indicator" v-if="doc.status == 'ACCEPTED'" >
                        <img :src="require('../assets/circle/approved.png')"/> 
                      </span>
                      <span  v-if="doc.status != 'SUBMITTED'" >{{ doc.processedBy }} on {{ formatDate(doc.updateDate) }}</span>
                      
                    </h6>
                    <div>
                       <button class="btn btn-view"  @click.prevent="viewUpload(doc)" v-b-tooltip.hover title="Click to view and action the document submitted by the applicant" >view</button>
                     
                    </div>
                    </button>

                  </li>
                  <li class="d-flex" v-for="doc in custProtectOnly" :key="doc.id">
                    <button class="btn-card-upload" disabled>
                      <h6>{{doc.description}} 
                        <span class="indicator" v-if="doc.status == 'REJECT'"  >
                        <img :src="require('../assets/circle/rejected.png')"/> 
                      </span>
                      <span class="indicator" v-if="doc.status == 'ACCEPTED'" >
                        <img :src="require('../assets/circle/approved.png')"/> 
                      </span>
                      <span  v-if="doc.status != 'SUBMITTED'" >{{ doc.processedBy }} on {{ formatDate(doc.updateDate) }}</span>
                      
                    </h6>
                    <div>
                       <button class="btn btn-view"  @click.prevent="viewUpload(doc)" v-b-tooltip.hover title="Click to view and action the document submitted by the applicant" >view</button>
                     
                    </div>
                    </button>
                  </li>
                  <li class="d-flex" v-for="doc in mtppOnly" :key="doc.id">
                    <button class="btn-card-upload" disabled>
                      <h6>{{doc.description}} 
                        <span class="indicator" v-if="doc.status == 'REJECT'"  >
                        <img :src="require('../assets/circle/rejected.png')"/> 
                      </span>
                      <span class="indicator" v-if="doc.status == 'ACCEPTED'" >
                        <img :src="require('../assets/circle/approved.png')"/> 
                      </span>
                      <span  v-if="doc.status != 'SUBMITTED'" >{{ doc.processedBy }} on {{ formatDate(doc.updateDate) }}</span>
                      
                    </h6>
                    <div>
                       <button class="btn btn-view"  @click.prevent="viewUpload(doc)" v-b-tooltip.hover title="Click to view and action the document submitted by the applicant" >view</button>
                      
                    </div>
                    </button>
                    
                  </li>
                  <li class="d-flex" v-for="doc in appointmentOnly" :key="doc.id">
                    <button class="btn-card-upload" disabled>
                      <h6>{{doc.description}} 
                        <span class="indicator" v-if="doc.status == 'REJECT'"  >
                        <img :src="require('../assets/circle/rejected.png')"/> 
                      </span>
                      <span class="indicator" v-if="doc.status == 'ACCEPTED'" >
                        <img :src="require('../assets/circle/approved.png')"/> 
                      </span>
                      <span  v-if="doc.status != 'SUBMITTED'" >{{ doc.processedBy }} on {{ formatDate(doc.updateDate) }}</span>
                      
                    </h6>
                    <div>
                       <button class="btn btn-view"  @click.prevent="viewUpload(doc)" v-b-tooltip.hover title="Click to view and action the document submitted by the applicant" >view</button>
                      
                    </div>
                    </button>
                    
                  </li>
                  <li class="d-flex" v-for="doc in rulesAndRegOnly" :key="doc.id">
                    <button class="btn-card-upload" disabled>
                      <h6>{{doc.description}} 
                        <span class="indicator" v-if="doc.status == 'REJECT'"  >
                        <img :src="require('../assets/circle/rejected.png')"/> 
                      </span>
                      <span class="indicator" v-if="doc.status == 'ACCEPTED'" >
                        <img :src="require('../assets/circle/approved.png')"/> 
                      </span>
                      <span  v-if="doc.status != 'SUBMITTED'" >{{ doc.processedBy }} on {{ formatDate(doc.updateDate) }}</span>
                      
                    </h6>
                    <div>
                       <button class="btn btn-view"  @click.prevent="viewUpload(doc)" v-b-tooltip.hover title="Click to view and action the document submitted by the applicant" >view</button>
                     
                    </div>
                    </button>

                  </li>
                  <hr>
                </form>
                </ul>
                <hr>
               <hr>
               <div >
                <div v-if="init.numberOfTokens== 2">
                    <p >To be confirmed by the Final Approver</p>
                    <div >
                      <button class="btn btn-approve" @click.prevent="openUploadApprover('3')" :disabled="!init.FXSIGN || !init.FXAPPROVERTWO" v-b-tooltip.hover title="To be confirmed by the final approver">
                        Confirm 
                      </button>
                    </div>
                </div>

              </div>
                
              </div>
              <div v-if="init.numberOfTokens >= 3">
                  <ul v-for="data in init.tokens" :key="data.id">
                    <li v-if="data.step == '3'">
                     <h6>Confirmed on <a :href="'https://explorer.blxq.org/tx/' + data.hash" target="_blank" class="link" v-b-tooltip.hover title="Click to view and verify on the blockchain">blockchain</a> by: {{ data.issuedBy }} on {{ formatDate(data.updateDate) }}</h6> 
                     <!-- <button class="btn btn-secondary"> <a :href="'https://explorer.blxq.org/tx/' + data.hash" target="_blank">view on blockchain</a></button> -->
                    </li>
                  </ul>
                   
              </div>
        </div>
        
    <ViewUpload v-if="showUpload" @close="viewUpload(docData)" :data="dataInfo"></ViewUpload>
    <FinalApprove v-if="showUploadApprover" @close="openUploadApprover()" :step="step"></FinalApprove>
    <UploadAoi v-if="openAoi" @close="uploadAoi()"></UploadAoi>
    <UploadAcceptanceAppt v-if="openAcceptanceAppt" @close="uploadAcceptanceAppt()"></UploadAcceptanceAppt>
    <UploadAfs v-if="openAfs" @close="uploadAfs()"></UploadAfs>
    <UploadAml v-if="openAml" @close="uploadAml()"></UploadAml>
    <UploadBoardReso v-if="openBoardReso" @close="uploadBoardReso()"></UploadBoardReso>
    <UploadCoi v-if="openCoi" @close="uploadCoi()"></UploadCoi>
    <UploadCpp v-if="openCpp" @close="uploadCpp()"></UploadCpp>
    <UploadDirectorCertificate v-if="openDirectorCertificate" @close="uploadDirectorCertificate()"></UploadDirectorCertificate>
    <UploadGis v-if="openGis" @close="uploadGis()"></UploadGis>
    <UploadIbrr v-if="openIbrr" @close="uploadIbrr()"></UploadIbrr>
    <UploadItr v-if="openItr" @close="uploadItr()"></UploadItr>
    <UploadMtpp v-if="openMtpp" @close="uploadMtpp()"></UploadMtpp>
    <UploadNameChange v-if="openNameChange" @close="uploadNameChange()"></UploadNameChange>
  </div>
  
</template>
        
<script>
  import moment from 'moment';
  import TheTopbar from '../components/TheTopbar.vue'
  import Header from '../components/Header.vue'
  import ViewUpload from '../components/modal/Documents/ViewUpload.vue'
  import FinalApprove from '../components/modal/Documents/FinalApprove.vue'
  import UploadAoi from '../components/modal/Documents/UploadAoi.vue'
  import UploadAcceptanceAppt from '../components/modal/Documents/UploadAcceptanceAppt.vue'
  import UploadAfs from '../components/modal/Documents/UploadAfs.vue'
  import UploadAml from '../components/modal/Documents/UploadAml.vue'
  import UploadBoardReso from '../components/modal/Documents/UploadBoardReso.vue'
  import UploadCoi from '../components/modal/Documents/UploadCoi.vue'
  import UploadCpp from '../components/modal/Documents/UploadCpp.vue'
  import UploadGis from '../components/modal/Documents/UploadGis.vue'
  import UploadIbrr from '../components/modal/Documents/UploadIbrr.vue'
  import UploadItr from '../components/modal/Documents/UploadItr.vue'
   import UploadMtpp from '../components/modal/Documents/UploadMtpp.vue'
   import UploadNameChange from '../components/modal/Documents/UploadNameChange.vue'
  import UploadDirectorCertificate from '../components/modal/Documents/UploadDirectorCertificate.vue';

  export default {
   
      name: 'Application',
      components: {
        TheTopbar,
        Header,
        ViewUpload,
        FinalApprove,
        UploadAoi,
        UploadAcceptanceAppt,
        UploadAfs,
        UploadAml,
        UploadBoardReso,
        UploadCoi,
        UploadCpp,
        UploadGis,
        UploadIbrr,
        UploadItr,
        UploadMtpp,
        UploadNameChange,
        UploadDirectorCertificate

      },
      computed: {
          show() {
              return this.$store.state.show;
          },
       },
       data: function() {
      return { 
        showUploadApprover: false,
          currentStep: 3,
          idTypes:[],
          isApproved: false,
          isRejected: false,
          showUpload: false,
          dataInfo:{},
          isLoading: false,
          isProfile: false,
          isApplicationForm: false,
          isProbityForm: false,
          isCompanyProbityForm: false,
          isInvestCommitment: false,
          isAoi: false,
          isCoi: false,
          isGis: false,
          isAfs: false,
          isDirectorsCert: false,
          isITR: false,
          isRules: false,
          isPolicies: false,
          isCPP: false,
          isNameChange: false,
          isBankRef: false,
          isAppointment: false,
          isBoardReso: false,
          isComplete: false,
          isApplicationFormApproved: false,
          isProbityFormApproved: false,
          isCompanyProbityFormApproved: false,
          isInvestCommitmentApproved: false,
          isAoiApproved: false,
          isBoardResoApproved: false,
          isAppointmentApproved: false,
          isBankRefApproved: false,
          isCoiApproved: false,
          isGisApproved: false,
          isAfsApproved: false,
          isDirectorsCertApproved: false,
          isITRApproved: false,
          isRulesApproved: false,
          isPoliciesApproved: false,
          isCPPApproved: false,
          isNameChangeApproved: false,
          status:'',
          applicationForm: 'APPLICATION FORM',
          personalProbity: 'PERSONAL PROBITY',
          companyProbity: 'COMPANY PROBITY',
          investCommitment: 'INVESTMENT COMMITMENT',
          aoi: 'ARTICLES OF INCORPORATION',
          coi: 'CERTIFICATE OF INCORPORATION',
          nameChange: 'CERTIFICATE OF NAME CHANGE',
          directorsCert: 'DIRECTORS CERTIFICATE',
          gis:'GENERAL INFO SHEET',
          afs:'AUDITED FINANCIAL STATEMENT',
          itr: 'INCOME TAX RETURN',
          rulesAndReg: 'INTERNAL BUSINESS RULES AND REGULATIONS',
          policies: 'INTERNAL AML/KYC/CFT POLICIES',
          custProtect: 'CUSTOMER PROTECTION PROGRAM',
          step: '3',
          isMtpp: false,
          isMtppApproved: false,
          mtpp: 'MTPP',
          openAoi: false,
          openCoi: false,
          openAml: false,
          openAcceptanceAppt: false,
          openAfs: false,
          openCpp: false,
          openDirectorCertificate: false,
          openGis: false,
          openIbrr: false,
          openItr: false,
          openMtpp: false,
          openNameChange: false,
          openBoardReso: false,


      }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
        applicationFormOnly(){
          return this.init.list.documentData.filter(item => item.fileType === this.applicationForm)
        },
        probePersonalOnly(){
          return this.init.list.documentData.filter(item => item.fileType === this.personalProbity)
        },
        probeCompanyOnly(){
          return this.init.list.documentData.filter(item => item.fileType === this.companyProbity)
        },
        investCommitOnly(){
          return this.init.list.documentData.filter(item => item.fileType === this.investCommitment)
        },
        aoiOnly(){
          return this.init.list.documentData.filter(item => item.fileType === this.aoi)
        },
        coiOnly(){
          return this.init.list.documentData.filter(item => item.fileType === this.coi)
        },
        mtppOnly(){
          return this.init.list.documentData.filter(item => item.fileType === this.mtpp)
        },
        nameChangeOnly(){
          return this.init.list.documentData.filter(item => item.fileType === this.nameChange)
        },
        directorsCertOnly(){
          return this.init.list.documentData.filter(item => item.fileType === this.directorsCert)
        },
        gisOnly(){
          return this.init.list.documentData.filter(item => item.fileType === this.gis)
        },
        afsOnly(){
          return this.init.list.documentData.filter(item => item.fileType === this.afs)
        },
        itrOnly(){
          return this.init.list.documentData.filter(item => item.fileType === this.itr)
        },
        
        rulesAndRegOnly(){
          return this.init.list.documentData.filter(item => item.fileType === this.rulesAndReg)
        },
        policiesOnly(){
          return this.init.list.documentData.filter(item => item.fileType === this.policies)
        },
        custProtectOnly(){
          return this.init.list.documentData.filter(item => item.fileType === this.custProtect)
        },
        appointmentOnly(){
          return this.init.list.documentData.filter(item => item.fileType === 'ACCEPTANCE OF APPOINTMENT')
        },
        boardResoOnly(){
          return this.init.list.documentData.filter(item => item.fileType === 'BOARD RESOLUTION')
        },
        bankRefOnly(){
          return this.init.list.documentData.filter(item => item.fileType === 'BANK REFERENCE')
        },
       
     
      },
      methods:{
        uploadAoi(){
          this.openAoi = !this.openAoi
          

        },
        uploadCoi(){
          this.openCoi = !this.openCoi
        },
        uploadAml(){
          this.openAml = !this.openAml
        },
        uploadAcceptanceAppt(){
          this.openAcceptanceAppt = !this.openAcceptanceAppt
        },
        uploadAfs(){
          this.openAfs = !this.openAfs
        },
        uploadCpp(){
          this.openCpp = !this.openCpp
        },
        UploadDirectorCertificate(){
          this.openDirectorCertificate = !this.openDirectorCertificate
        },
        uploadGis(){
          this.openGis = !this.openGis
        },
        uploadIbrr(){
          this.openIbrr = !this.openIbrr
        },
        uploadItr(){
          this.openItr = !this.openItr
        },
        uploadMtpp(){
          this.openMtpp = !this.openMtpp
        },
        uploadNameChange(){
          this.openNameChange = !this.openNameChange
        },
        uploadBoardReso(){
          this.openBoardReso = !this.openBoardReso
        },
        openUploadApprover(step){
          this.step = step
          this.showUploadApprover = !this.showUploadApprover
        },
        
        goToProfile(){
          window.location.href="/#/customer-profile/" + this.$route.params.entityId
        },
        getPoid(){
          this.isLoading = true
          const params = {
            entityId: this.$route.params.entityId
            }
           
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/customer/businessfile/'
              }
              this.axios(options)
              .then((response) => {
             this.isLoading = false
                this.init.list.documentData = response.data
                this.init.list.documentData.forEach((el, i) => {
                  if(el.fileType !== 'INTENTLETTER' && el.fileType !== 'BUSINESSPLAN'){
                    this.init.list.documents = response.data
                    this.fileType = el.fileType
                  }
                
                  if(this.fileType == 'APPLICATION FORM') {
                    this.isApplicationForm = true
                    if(el.status == 'ACCEPTED'){
                      this.isApplicationFormApproved = true
                    }
                  }
                  if(this.fileType == 'PERSONAL PROBITY') {
                    this.isProbityForm = true
                    if(el.status == 'ACCEPTED'){
                      this.isProbityFormApproved = true
                    }
                  }
                  if(this.fileType == 'COMPANY PROBITY') {
                    this.isCompanyProbityForm = true
                    if(el.status == 'ACCEPTED'){
                      this.isCompanyProbityFormApproved = true
                    }

                  }
                  if(this.fileType == 'BOARD RESOLUTION') {
                    this.isBoardReso = true
                    if(el.status == 'ACCEPTED'){
                      this.isBoardResoApproved = true
                    }

                  }
                  if(this.fileType == 'INVESTMENT COMMITMENT') {
                    this.isInvestCommitment = true
                    if(el.status == 'ACCEPTED'){
                      this.isInvestCommitmentApproved = true
                    }
                  }
                  if(this.fileType == 'ARTICLES OF INCORPORATION') {
                    this.isAoi = true
                    if(el.status == 'ACCEPTED'){
                      this.isAoiApproved = true
                    }
                  }
                  
                 if(this.fileType == 'CERTIFICATE OF INCORPORATION') {
                   this.isCoi = true
                   if(el.status == 'ACCEPTED'){
                      this.isCoiApproved = true
                    }

                   }
                if(this.fileType == 'CERTIFICATE OF NAME CHANGE') {
                   this.isNameChange = true
                   if(el.status == 'ACCEPTED'){
                      this.isNameChangeApproved = true
                    }
                   }
                   if(this.fileType == 'DIRECTORS CERTIFICATE') {
                   this.isDirectorsCert = true
                   if(el.status == 'ACCEPTED'){
                      this.isDirectorsCertApproved = true
                    }
                   }
                   if(this.fileType == 'MTPP') {
                   this.isMtpp = true
                   if(el.status == 'ACCEPTED'){
                      this.isMtppApproved = true
                    }
                   }
                   if(this.fileType == 'ACCEPTANCE OF APPOINTMENT') {
                   this.isAppointment = true
                   if(el.status == 'ACCEPTED'){
                      this.isAppointmentApproved = true
                    }
                   }
                   if(this.fileType == 'GENERAL INFO SHEET') {
                   this.isGis = true
                   if(el.status == 'ACCEPTED'){
                      this.isGisApproved = true
                    }
                   }
                   if(this.fileType == 'INCOME TAX RETURN') {
                    this.isITR = true
                    if(el.status == 'ACCEPTED'){
                      this.isITRApproved = true
                    }
                  }
                  if(this.fileType == 'INTERNAL BUSINESS RULES AND REGULATIONS') {
                    this.isRules = true
                    if(el.status == 'ACCEPTED'){
                      this.isRulesApproved = true
                    }
                  }
                  if(this.fileType == 'AUDITED FINANCIAL STATEMENT') {
                    this.isAfs = true
                    if(el.status == 'ACCEPTED'){
                      this.isAfsApproved = true
                    }
                  }
                  if(this.fileType == 'INTERNAL AML/KYC/CFT POLICIES') {
                    this.isPolicies = true
                    if(el.status == 'ACCEPTED'){
                      this.isPoliciesApproved = true
                    }
                  }
                  if(this.fileType == 'CUSTOMER PROTECTION PROGRAM') {
                    this.isCPP = true
                    if(el.status == 'ACCEPTED'){
                      this.isCPPApproved = true
                    }
                  }
                  this.isComplete = false;
                  if(
                  //   this.isApplicationFormApproved == true &&
                  // this.isProbityFormApproved == true &&
                  // this.isCompanyProbityFormApproved == true &&
                  // this.isInvestCommitmentApproved == true &&
                  this.isAoiApproved == true &&
                  this.isCoiApproved == true &&
                  this.isGisApproved == true &&
                  this.isAfsApproved == true &&
                  this.isITRApproved == true &&
                  this.isDirectorsCertApproved == true &&
                  this.isRulesApproved == true &&
                  this.isPoliciesApproved == true &&
                  this.isCPPApproved == true ){
                    this.isComplete = true
                  }
                
                  })
               
              }).catch((err) => {
                  
              })
        },
        viewUpload(docData){
          this.dataInfo = docData
          this.showUpload = !this.showUpload
        },
       
          formatDate: function(time){
              return new Date().toDateString();
          },
         
          getProfile() {
            const params = {
              eid: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              data,
              url: '/ax/getCustomerProfile/',
              }
              this.axios(options)
              .then((response) => {
                
                this.show.profile = response.data;
                this.show.corpDetails = response.data.corpDetails
                
              
              }).catch((err) => {
                  
              })
          },
       

        
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
        this.axios(options)
              .then((response) => {
              
              this.getProfile();
              }).catch((err) => {
                 

                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
     
      mounted(){
          this.sessionCheck();
          this.getPoid();
          
      }
  }
</script>
        
<style scoped lang="scss">
    * {
    box-sizing: border-box;
    color: white;
  }
  
  /* Create two equal columns that floats next to each other */
  .column {
    float: left;
    width: 50%;
    padding: 10px;
  
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .placeholder-circle {
    width: 25px;
    height: 25px;
    border: 1px solid #ccc; /* Gray background */
    border-radius: 50%; /* Makes it a circle */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
  }
  .approved-circle{
    width: 25px;
    height: 25px;
    background-color: #c69903; /* Gray background */
    border-radius: 50%; /* Makes it a circle */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
  }
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }
  .checkbox label{
    font-size: 12px !important;
  }
.header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }

  .content-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    justify-content: center !important;
    /* max-width: 1000px; */
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #ffffff;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
    
  }
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .btn-approve{
       width: 100%;
       background-color: green !important;
        color: white;
    }
  .progress-bar {
    padding: 20px;
    height: 75px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 10px;
    white-space: wrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Show ellipsis (...) for overflowing text */
    padding: 5px 10px; /* Adjust the padding as needed to fit text */
    max-width: 100%; /* Ensure the button doesn't grow beyond its container */
    font-size: 10px; /* Adjust the font size as needed */
    color: black;
    margin: 2px;
    }
  .active {
    background-color: #695536;
  }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}
.btn .btn-checkbox{
  padding: 0px !important;
  margin: 0px !important;
}
.btn-checkbox .checkbox{
  width: 20px !important;
}
label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}
.button{
  background-color: transparent !important;
  border: transparent !important;
}
   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}




   

.icon img{
  width: 2rem;
}
.card{
  background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
  );
    box-shadow:0 0 30px rgba(0,0,0,0.3);
    border: 1px solid transparent;
    border-radius: 12px;
    width: 100%;
    height: auto;
    text-align: center;
    color: white;
}


.verified{
  background-color: green;
  color: white;
}
.submitted{
  background-color: green !important;
  color: white;
}
.notSubmitted{
  background-color: red;
  
}
  
 
  
    .progress-bars {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    /* margin-bottom: 20px; */
  }
  
 
  
  .active {
    background-color: #b47e27 !important;
    color: white !important;
  }

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }

  
  
}
@media (max-width: 1280px) {

  
}
  
  label {
    margin-right: 5px;
  }
  

.btn-card-upload{
  width: 100%;
  height: auto;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: gray !important;
  color: black;
  display: flex;
  justify-content: space-between;
 
}

.indicator img{
  width: 1rem;
}
.indicator-progress img{
  width: 1rem;
}
.loading-spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #007BFF; /* Change the color to your preferred color */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
